import React from "react";

import { Link } from "react-router-dom";

import Tooltip from "../../components/tooltip/Tooltip";

import { ReactComponent as BackArrow } from "../../assets/images/backarrow.svg";
// import appUrls from '../../services/AppURLs';

import "../styles/button.css";

interface BackProps {
  name?: string;
  btntype: "link" | "button";
  onClick?: any;
  addClass?: string;
}

export const Back: React.FC<BackProps> = ({
  btntype,
  onClick,
  addClass,
  name,
}) => {
  return (
    <>
      <Tooltip content="Back" direction="top">
        {btntype === "button" && (
          <button
            type="button"
            className={`backbtn ${addClass ? addClass : ""}`}
            onClick={onClick}
          >
            <BackArrow />
          </button>
        )}
        {btntype === "link" && (
          <Link className={`backbtn ${addClass ? addClass : ""}`} to={onClick}>
            <BackArrow />
          </Link>
        )}
      </Tooltip>
    </>
  );
};
