import React from "react";

interface IPDFViewerProps {
  renderData: any;
}

const PDFViewer = ({ renderData }: IPDFViewerProps) => (
  <iframe
    id="pdfviewer"
    title="pdfviewer"
    style={{ height: "100%", width: "100%" }}
    src={renderData}
  />
);

export default PDFViewer;
