import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { confirm } from 'react-confirm-box';
import { useAppDispatch } from '../../app/hooks';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import jwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import {
  createTrust,
  getTrustList,
  getClientCredentials,
  trustListSelector,
  deleteTrust,
  trustActivateOrDeactivateAPI,
  updateClientCredentials,
} from '../../features/Trust/TrustSlice';
import { sendResetLinkToUser } from '../../features/User/UserSlice';
import sortDownIcon from '../../assets/images/arrow-down-short.svg';
import sortUpIcon from '../../assets/images/arrow-up-short.svg';
import copyIcon from '../../assets/images/copy-icon.svg';

import './Level1User.css';
import Pagination from '../../components/Pagination';
import { Constants } from '../../global/Constants';
import { FormInput } from '../../components/FormInput';
import CustomModal from '../../components/CustomModal';
import Toast from '../../components/Toast';
import { Search } from '../../components/Search';
import {
  createLevel1User,
  deleteLevel1,
  getLevel1UserList,
  level1ActivateOrDeactivateAPI,
  level1UserSelector,
} from '../../features/Level1User/level1UserSlice';
import Loader from '../../components/loading/Loading';
import Loading from '../../components/loading/Loading';
import { FormSelect } from '../../components/FormSelect';
import helperFunctions from '../../global/HelperService';

const regex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const emailPattern = {
  value: new RegExp(regex),
  message: 'Enter a valid email address.',
};

const textPattern = {
  value: new RegExp('/^(?=s*S).{1,50}$/g'),
  message: 'Enter a valid user name.',
};

const Level1User = () => {
  const dispatch = useAppDispatch();
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [isClientCredsModalOpen, setClientCredsModel] =
    useState<boolean>(false);
  const [sort, setSort] = useState<{ sortField: string; sortOrder: number }>({
    sortField: 'name',
    sortOrder: 1,
  });
  const [searchText, setSearchText] = useState<string>('');
  const timerId: React.MutableRefObject<number | undefined> = useRef<number>();
  const level1Users: any = useSelector(level1UserSelector);
  const [pageNo, setPageNo] = React.useState(1);
  const [copyId, setCopyId] = useState(false);
  const [copySecret, setCopySecret] = useState(false);
  const [tpId, setTpId] = useState(false);
  const [isSendResetLinkModalOpen, setSendResetLinkModalOpen] =
    useState<boolean>(false);
  const [sendLinkTo, setSendLinkTo] = useState<string>('');
  const [isSentResetLinkModalOpen, setSentResetLinkModalOpen] =
    useState<boolean>(false);
  const [isCreateTrustModalOpen, setCreateTrustModalState] = useState(false);
  const [isDeleteTrustModalOpen, setDeleteTrustModalOpen] = useState(false);
  const [deleteLevelid, setDeleteLevel1Id] = useState<string>('');
  const [validationError, setValidationError] = useState<string>('');
  const jwtDecode: any = jwt(localStorage.getItem('token') || '');

  const [
    isActivateDeactivateWarningModel,
    setIsActivateDeactivateWarningModel,
  ] = useState<boolean>(false);
  const [modelTxt, setModelTxt] = useState<string>('');
  const [toActivateOrDeactivate, setToActivateOrDeactivate] =
    useState<boolean>(false);

  const [btnDisabled, setbtnDisabled] = useState(false);

  const {
    register: register,
    reset: reset,
    handleSubmit: handleSubmit,
    trigger,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: 'all' });
  const navigate = useNavigate();
  const [clientCreds, setClientCreds] = useState<any>({});
  const [level1Id, setLevel1Id] = useState<any>();
  const [loader, setloader] = useState(false);
  const trustEnableDisableDD = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];

  useEffect(() => {
    fetchTrusts();
  }, [sort.sortOrder, sort.sortField]);

  useEffect(() => {
    trigger();
  }, [trigger, isCreateTrustModalOpen]);

  const handleSearchChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(timerId.current);
      setSearchText(value);
      setPageNo(1);
      timerId.current = setTimeout(() => {
        // fetchTrusts({ page: 1, searchString: value });
      }, 500) as any;
    },
    []
  );

  const handleSort = (sortField: string) => {
    setSort((prevState) => ({
      sortField,
      sortOrder: prevState.sortOrder === 1 ? -1 : 1,
    }));
  };

  const toggleSearch = () => {
    if (showSearch) {
      //  fetchTrusts({ page: 1, searchString: "" });
    }
    setShowSearch(!showSearch);
    setSearchText('');
    setPageNo(1);
  };

  const activeHandler = (clickedActive: string) => {
    setPageNo(parseInt(clickedActive));
    //fetchTrusts({ page: clickedActive });
  };

  const viewLevel1UserCreds = (id: string) => {
    let data = { level1UserId: id };
    setLevel1Id(id);
    setloader(true);
    dispatch(getClientCredentials(data))
      .unwrap()
      .then((data: any) => {
        setClientCreds(data.data);
        setClientCredsModel(true);
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };

  const fetchTrusts = (requestQuery?: any) => {
    let level1Id = jwtDecode.level1Id;
    setloader(true);
    dispatch(
      getLevel1UserList({
        level1Id: level1Id,
        sortOrder: sort.sortOrder,
        sortBy: sort.sortField,
        searchString: searchText,
        page: pageNo,
        perPage: Constants.ROWS_PER_PAGE,
        ...requestQuery,
      })
    )
      .unwrap()
      .then((data: any) => {
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };

  const onCreateTrustModalClose = () => {
    setValidationError('');
    setCreateTrustModalState(false);
    reset();
  };

  const onCreateTrustSubmit = (level1User: any) => {
    level1User['roles'] = 'System Admin';
    setloader(true);
    dispatch(createLevel1User(level1User))
      .unwrap()
      .then((data: any) => {
        onCreateTrustModalClose();
        fetchTrusts();
        // toast.custom((t) => (
        //   <Toast
        //     id={t.id}
        //     title="SUCCESS"
        //     content={Constants.LEVEL1_ADD_SUCCESS}
        //   />
        // ));
        helperFunctions.showToast(`${Constants.LEVEL1_ADD_SUCCESS}`, 'success');

        setloader(false);
      })
      .catch((e) => {
        setValidationError(e.data.message);
        setloader(false);
      });
  };

  const createTrustModal = {
    isOpen: isCreateTrustModalOpen,
    title: 'Add Client',
    content: (
      <form
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        className='tex-align-l user-form mx-auto'
      >
        <div className='error mt-2'>{validationError}</div>
        <FormInput
          id='name'
          type='text'
          name='name'
          label='User name'
          placeholder='Enter user name'
          //isLogin={true}
          register={register}
          rules={{
            required: 'This field is required',
            pattern: {
              value: /^(?=\s*\S).{1,50}$/g,
              message: 'This field is required',
            },
          }}
          errors={errors}
          // onChange={()=>{setValidationError("")}}
        />
        <FormInput
          id='email'
          type='text'
          name='email'
          label='Email address'
          placeholder='Enter system admin email address'
          register={register}
          // isLogin={true}
          rules={{
            required: true,
            pattern: emailPattern,
          }}
          errors={errors}
          // onChange={()=>{setValidationError("")}}
        />
        <>
          <FormSelect
            label='Trust based'
            id='trustBased'
            name='trustBased'
            placeholder='Please select..'
            isSearchable={false}
            options={trustEnableDisableDD}
            labelKey='label'
            valueKey='value'
            control={control}
            // selectedValue={user.roles?.roles.toString()}
            errors={errors}
          />
        </>
        <div className='text-center'>
          <button
            className='btn btn-violet mt-4'
            onClick={handleSubmit(onCreateTrustSubmit)}
            disabled={!isValid}
          >
            Create System Admin
          </button>
        </div>
      </form>
    ),
    closeAction: onCreateTrustModalClose,
  };

  const onClientCredsModalClose = () => {
    setClientCredsModel(false);
    setClientCreds({});
    setCopyId(false);
    setCopySecret(false);
    setTpId(false);
    setLevel1Id('');
  };

  const handleRegenerateTokenAPI = (id: string) => {
    console.log('jwtDecode.level1Id ', id);
    let data = { level1UserId: id };
    dispatch(updateClientCredentials(data))
      .unwrap()
      .then((data: any) => {
        setClientCreds(data.data);
        setClientCredsModel(true);
      })
      .catch((e) => {
        console.log('error---', e);
      });
  };

  const copyTextFunction = (value: any, flag: any) => {
    if (flag === 1) {
      setCopyId(!copyId);
    } else if (flag === 2) {
      setCopySecret(!copySecret);
    } else {
      setTpId(!tpId);
    }
    navigator.clipboard.writeText(value);
  };
  const clientCredsModal = {
    isOpen: isClientCredsModalOpen,
    title: 'Client credentials',
    content: (
      <>
        <div className='row'>
          <div className='col-sm-8'>
            <div className='input-group input-group-cred'>
              <p className='copy-label'>Client ID:</p>
              <input
                className='form-control detail-input'
                type='text'
                value={clientCreds.clientId || ''}
                disabled={true}
              />
            </div>
          </div>
          <div className='col-sm-4 copy-div'>
            <button
              className={copyId ? 'btn copied-flag' : 'btn secondary-btn'}
              onClick={() => copyTextFunction(clientCreds.clientId, 1)}
            >
              {copyId ? 'Copied' : 'Copy'}
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-8'>
            <div className='input-group input-group-cred'>
              <p className='copy-label'>Client Secret:</p>
              <input
                className='form-control detail-input'
                type='text'
                value={clientCreds.clientSecret || ''}
                disabled={true}
              />
            </div>
          </div>
          <div className='col-sm-4 copy-div'>
            <button
              className={copySecret ? 'btn copied-flag' : 'btn secondary-btn'}
              onClick={() => copyTextFunction(clientCreds.clientSecret, 2)}
            >
              {copySecret ? 'Copied' : 'Copy'}
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-8'>
            <div className='input-group input-group-cred'>
              <p className='copy-label'>Third party id:</p>
              <input
                className='form-control detail-input'
                type='text'
                value={clientCreds.tpId || ''}
                disabled={true}
              />
            </div>
          </div>
          <div className='col-sm-4 copy-div'>
            <button
              className={tpId ? 'btn copied-flag' : 'btn secondary-btn'}
              onClick={() => copyTextFunction(clientCreds.tpId, 3)}
            >
              {tpId ? 'Copied' : 'Copy'}
            </button>
          </div>
        </div>
        {/* <button
          className="btn secondary-btn"
          onClick={() => handleRegenerateTokenAPI(level1Id)}
        >
          Regenerate
        </button> */}
      </>
    ),
    closeAction: onClientCredsModalClose,
  };

  const onSendResetLinkModalClose = () => {
    setSendResetLinkModalOpen(false);
  };
  const onSentResetLinkModalClose = () => {
    setSentResetLinkModalOpen(false);
  };
  const viewResetLinkModal = (user_id: string) => {
    setSendLinkTo(user_id);
    setSendResetLinkModalOpen(true);
  };
  const sendRestLink = () => {
    if (sendLinkTo) {
      setbtnDisabled(true);
      setloader(true);
      dispatch(sendResetLinkToUser(sendLinkTo))
        .unwrap()
        .then((data: any) => {
          setSendLinkTo('');
          setSendResetLinkModalOpen(false);
          setbtnDisabled(false);
          // toast.custom((t) => (
          //   <Toast
          //     id={t.id}
          //     title="SUCCESS"
          //     content={Constants.RESET_LINK_SENT_SUCCESS}
          //   />
          // ));
          helperFunctions.showToast(
            `${Constants.RESET_LINK_SENT_SUCCESS}`,
            'success'
          );

          setloader(false);
        })
        .catch((e) => {
          setbtnDisabled(false);
          console.log('error---', e);
          setloader(false);
        });
    }

    // setSentResetLinkModalOpen(true);
  };
  const sendResetLinkModal = {
    isOpen: isSendResetLinkModalOpen,
    title: '',
    content: (
      <>
        <div className='row'>
          <div className='col-sm-12' style={{ marginBottom: '40px' }}>
            <p>Are you sure you want to send a reset link?</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <button
              className='btn secondary-btn'
              disabled={btnDisabled}
              onClick={() => sendRestLink()}
            >
              YES, SEND LINK
            </button>
          </div>
        </div>
        <div className='row'>
          <div
            className='col-sm-12'
            style={{
              marginTop: '40px',
              fontSize: '12pt',
              fontWeight: 'bolder',
            }}
          >
            <span
              className='blue-link'
              onClick={() => setSendResetLinkModalOpen(false)}
            >
              CANCEL
            </span>
          </div>
        </div>
      </>
    ),
    closeAction: onSendResetLinkModalClose,
  };

  const sentResetLinkModal = {
    isOpen: isSentResetLinkModalOpen,
    title: 'Success',
    content: (
      <>
        <div className='row'>
          <div className='col-sm-12' style={{ marginBottom: '40px' }}>
            <p>Reset link for password has been sent</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <button
              className='btn secondary-btn'
              onClick={() => setSentResetLinkModalOpen(false)}
            >
              CLOSE
            </button>
          </div>
        </div>
      </>
    ),
    closeAction: onSentResetLinkModalClose,
  };

  const onDeleteTrustModalClose = () => {
    setDeleteLevel1Id('');
    setDeleteTrustModalOpen(false);
  };
  const deleteTrustModal = {
    isOpen: isDeleteTrustModalOpen,
    title: '',
    content: (
      <>
        <div className='row'>
          <div className='col-sm-12' style={{ marginBottom: '40px' }}>
            <p>Are you sure you want to archive this system admin?</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <button
              className='btn secondary-btn'
              disabled={btnDisabled}
              onClick={() => removeLevel1(deleteLevelid)}
            >
              YES, ARCHIVE
            </button>
          </div>
        </div>
        <div className='row'>
          <div
            className='col-sm-12'
            style={{
              marginTop: '40px',
              fontSize: '12pt',
              fontWeight: 'bolder',
            }}
          >
            <span
              className='blue-link'
              onClick={() => setDeleteTrustModalOpen(false)}
            >
              CANCEL
            </span>
          </div>
        </div>
      </>
    ),
    closeAction: onDeleteTrustModalClose,
  };

  const removeLevel1Modal = async (id: string) => {
    setDeleteLevel1Id(id);
    setDeleteTrustModalOpen(true);
    return;
  };

  const removeLevel1 = async (id: string) => {
    if (id) {
      setbtnDisabled(true);
      setloader(true);
      dispatch(
        deleteLevel1({
          level1Id: id,
        })
      )
        .unwrap()
        .then((data: any) => {
          // toast.custom((t) => (
          //   <Toast
          //     id={t.id}
          //     title="SUCCESS"
          //     content={Constants.SYSTEM_ADMIN_DELETED}
          //   />
          // ));
          helperFunctions.showToast(
            `${Constants.SYSTEM_ADMIN_DELETED}`,
            'success'
          );

          fetchTrusts();
          setDeleteTrustModalOpen(false);
          setbtnDisabled(false);
          setloader(false);
        })
        .catch((e) => {
          setbtnDisabled(false);
          console.log('error---', e);
          setloader(false);
        });
    }
  };

  const activateDeactivatePopupClose = () => {
    setIsActivateDeactivateWarningModel(false);
  };
  const activateDeactivateWarningModel = {
    isOpen: isActivateDeactivateWarningModel,
    title: '',
    content: (
      <>
        <div className='row'>
          <div className='col-sm-12' style={{ marginBottom: '40px' }}>
            <p>{modelTxt}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <button
              className='btn secondary-btn'
              disabled={btnDisabled}
              onClick={() => trustActivateOrDeactivate(deleteLevelid)}
            >
              YES
            </button>
          </div>
        </div>
        <div className='row'>
          <div
            className='col-sm-12'
            style={{
              marginTop: '40px',
              fontSize: '12pt',
              fontWeight: 'bolder',
            }}
          >
            <span
              className='blue-link'
              onClick={() => setIsActivateDeactivateWarningModel(false)}
            >
              CANCEL
            </span>
          </div>
        </div>
      </>
    ),
    closeAction: activateDeactivatePopupClose,
  };

  const activateDeactivateLevel1 = async (id: string, isActivate: boolean) => {
    console.log(isActivate);
    setModelTxt((txt) =>
      isActivate
        ? 'Are you sure you want to deactivate this system admin?'
        : 'Are you sure you want to activate this system admin?'
    );
    setDeleteLevel1Id(id);
    setToActivateOrDeactivate(!isActivate);
    setIsActivateDeactivateWarningModel(true);
    return;
  };
  const trustActivateOrDeactivate = async (id: string) => {
    if (id) {
      setbtnDisabled(true);
      setloader(true);
      dispatch(
        level1ActivateOrDeactivateAPI({
          level1Id: id,
          isActive: toActivateOrDeactivate,
        })
      )
        .unwrap()
        .then((data: any) => {
          console.log(
            '🚀 ~ file: TrustList.tsx ~ line 524 ~ .then ~ data',
            data
          );
          // toast.custom((t) => (
          //   <Toast id={t.id} title="SUCCESS" content={data.message} />
          // ));
          helperFunctions.showToast(`${data.message}`, 'success');

          fetchTrusts();
          setIsActivateDeactivateWarningModel(false);
          setbtnDisabled(false);
          setloader(false);
        })
        .catch((e) => {
          setbtnDisabled(false);
          setloader(false);
          console.log('error---', e);
        });
    }
  };
  // console.log(formState.errors);
  return (
    <div className='trust-management'>
      <Loading status={loader} />
      <CustomModal {...clientCredsModal} />
      <CustomModal {...createTrustModal} />
      <CustomModal {...sendResetLinkModal} />
      <CustomModal {...sentResetLinkModal} />
      <CustomModal {...deleteTrustModal} />
      <CustomModal {...activateDeactivateWarningModel} />
      <div className=''>
        <div className='header fw-bold mb-3'>Super Admin management</div>
        <div className='app-sub-header'>
          <div className='row g-0 flex items-center'>
            <Search handleSearchChange={handleSearchChange} />
            <div className='col-md-6 col-12 flex align-items-end justify-content-end d-none'>
              <button
                className='btn secondary-btn'
                onClick={() => {
                  trigger();
                  setCreateTrustModalState(true);
                }}
              >
                Add Client
              </button>
            </div>
          </div>
        </div>
        <div className='list-container table-responsive'>
          <div className='horizontal-scrolll'>
            <table className='table table-light trust-list scroll-content'>
              <thead>
                <tr>
                  <th scope='col'>
                    Name
                    <img
                      src={
                        sort.sortField === 'name' && sort.sortOrder === -1
                          ? sortUpIcon
                          : sortDownIcon
                      }
                      alt='sort-icon'
                      onClick={() => handleSort('name')}
                    />
                  </th>
                  <th scope='col'>
                    Email Address
                    <img
                      src={
                        sort.sortField === 'contact.email' &&
                        sort.sortOrder === -1
                          ? sortUpIcon
                          : sortDownIcon
                      }
                      alt='sort-icon'
                      onClick={() => handleSort('contact.email')}
                    />
                  </th>
                  <th scope='col'>Password</th>
                  <th scope='col'>Client Credentials</th>
                  <th scope='col'>
                    Status
                    <img
                      src={
                        sort.sortField === 'isActive' && sort.sortOrder === -1
                          ? sortUpIcon
                          : sortDownIcon
                      }
                      alt='sort-icon'
                      onClick={() => handleSort('isActive')}
                    />
                  </th>
                  <th scope='col'>Archive system admin</th>
                </tr>
              </thead>
              <tbody>
                {level1Users &&
                level1Users?.level1UserList?.level1Users?.length > 0 ? (
                  level1Users?.level1UserList?.level1Users.map(
                    (level1User: any) => (
                      <tr key={level1User._id}>
                        <td>{level1User.name}</td>
                        <td>{level1User.contact.email}</td>
                        <td
                          className='cursor-pointer'
                          onClick={() =>
                            viewResetLinkModal(level1User.trusts[0])
                          }
                        >
                          <span className='blue-link'>Send reset link</span>
                        </td>
                        <td
                          className='cursor-pointer'
                          onClick={() => viewLevel1UserCreds(level1User._id)}
                        >
                          <span className='blue-link'>View</span>
                        </td>
                        <td
                          className='cursor-pointer'
                          onClick={() =>
                            activateDeactivateLevel1(
                              level1User._id,
                              level1User.isActive
                            )
                          }
                        >
                          <span className='blue-link'>
                            {level1User.isActive ? 'Deactivate' : 'Activate'}
                          </span>
                        </td>
                        <td
                          className='cursor-pointer'
                          onClick={() => removeLevel1Modal(level1User._id)}
                        >
                          <span className='blue-link'>
                            Archive system admin
                          </span>
                        </td>
                      </tr>
                    )
                  )
                ) : level1Users.isFetching ? (
                  <Loader status={true} />
                ) : (
                  <tr>
                    <td colSpan={6} className='text-center'>
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {level1Users?.level1UserList?.total > 10 && (
            <Pagination
              active={pageNo}
              size={
                level1Users.level1UserList.total
                  ? Math.ceil(
                      level1Users.level1UserList.total / Constants.ROWS_PER_PAGE
                    )
                  : 1
              }
              step={2}
              onClickHandler={activeHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Level1User;
