import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { TableCustomStyle } from "./TableCustomStyle";

const ResourceListTable = ({
  resources,
  sort,
  handleSort,
  onResourceClick,
  decodeEntities,
  isSharedResource,
  tabIndex,
}) => {
  const columns = [
    {
      name: "Resource title",
      selector: (row) => decodeEntities(row.title),
      sortable: true,
      sortFunction: (rowA, rowB) => rowA.title.localeCompare(rowB.title),
      width: "150px",
      cell: (row) => (
        <div className="resource-heading">
          <p>{decodeEntities(row.title)}</p>
        </div>
      ),
    },
    {
      name: "Resource type",
      selector: (row) => row.type.replace(/&amp;/g, "&"),
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div className="resource-heading">
          <p>{row.type.replace(/&amp;/g, "&")}</p>
        </div>
      ),
    },
    {
      name: "Topic",
      selector: (row) => row.topic,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div className="resource-heading">
          <p>{row.topic}</p>
        </div>
      ),
    },
    {
      name: "Created date",
      selector: (row) =>
        row.createdAt
          ? moment(new Date(row.createdAt)).format("DD/MM/YYYY")
          : "",
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div className="resource-heading">
          <p>
            {row.createdAt
              ? moment(new Date(row.createdAt)).format("DD/MM/YYYY")
              : ""}
          </p>
        </div>
      ),
    },
    {
      name: "Approved date",
      selector: (row) =>
        row.approvedInfo
          ? moment(new Date(row.approvedInfo.at)).format("DD/MM/YYYY")
          : "Awaiting approval",
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div className="resource-heading">
          <p>
            {row.approvedInfo
              ? moment(new Date(row.approvedInfo.at)).format("DD/MM/YYYY")
              : "Awaiting approval"}
          </p>
        </div>
      ),
    },
    {
      name: "Approval sent to",
      selector: (row) =>
        row.selectedApprover && row.selectedApprover.name
          ? row.selectedApprover.name
          : "-",
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div className="resource-heading">
          <p>
            {row.selectedApprover && row.selectedApprover.name
              ? row.selectedApprover.name
              : "-"}
          </p>
        </div>
      ),
    },
    {
      name: "Author",
      selector: (row) => row?.owner?.name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => (row?.status ? row?.status : ""),
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div
          className="resource-heading"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            minHeight: "70px",
            height: "70px",
          }}
        >
          <p>{row?.status ? row?.status : ""}</p>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          onClick={() => onResourceClick(row._id)}
          className="underline-btn primary"
        >
          View
        </button>
      ),
      width: "100px",
    },
  ];

  const data = resources?.contents?.map((resource, idx) => ({
    ...resource,
    id: idx,
  }));

  return (
    <DataTable
      columns={columns}
      data={data}
      noDataComponent="No data found"
      keyField="id"
      highlightOnHover
      pointerOnHover
      customStyles={TableCustomStyle}
      responsive
    />
  );
};

export default ResourceListTable;
