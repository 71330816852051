export const modules = {
  oauth: [
    {
      name: 'google',
      label: 'Google',
      active: true,
    },
    {
      name: 'azure',
      label: 'Azure Active Directory',
      active: true,
    },
    {
      name: 'nhs',
      label: 'NHS login',
      active: true,
    },
  ],
  myHealth: [
    {
      name: 'conditions_and_medications',
      label: 'My conditions and medications',
      active: true,
    },
    {
      name: 'appointment',
      label: 'Appointment',
      active: true,
    },
    {
      name: 'questionnaires',
      label: 'Questionnaires',
      active: true,
    },
    {
      name: 'my_lifestyle',
      label: 'My lifestyle',
      active: true,
    },
  ],
  connect: [
    {
      name: 'connect',
      label: 'Members of your circle',
      active: true,
    },
  ],
  tracker: [
    {
      name: 'mood',
      label: 'Mood tracker',
      active: true,
    },
    {
      name: 'alcohol',
      label: 'Alcohol tracker',
      active: true,
    },
    {
      name: 'pain',
      label: 'Pain tracker',
      active: true,
    },
    {
      name: 'problem',
      label: 'Problem tracker',
      active: true,
    },
    {
      name: 'sleep',
      label: 'Sleep tracker',
      active: true,
    },
    {
      name: 'smoking',
      label: 'Smoking tracker',
      active: true,
    },
    {
      name: 'bmi',
      label: 'BMI tracker',
      active: true,
    },
    {
      name: 'temperature',
      label: 'Temperature tracker',
      active: true,
    },
    {
      name: 'exercise',
      label: 'Exercise tracker',
      active: true,
    },
    {
      name: 'diet',
      label: 'Diet tracker',
      active: true,
    },
    {
      name: 'fluid',
      label: 'Fluid tracker',
      active: true,
    },
    {
      name: 'period',
      label: 'Period tracker',
      active: true,
    },
    {
      name: 'dailySteps',
      label: 'Daily steps',
      active: true,
    },
    {
      name: 'headache',
      label: 'Headache tracker',
      active: true,
    },
    {
      name: 'preHeadache',
      label: 'Aura Tracker',
      active: true,
    },
    {
      name: 'bloodPressure',
      label: 'Blood pressure tracker',
      active: true,
    },
    {
      name: 'coping',
      label: 'Coping strategies',
      active: true,
    },
    {
      name: 'diary',
      label: 'Diary',
      active: true,
    },
    {
      name: 'goal',
      label: 'Goal',
      active: true,
    },
    {
      name: 'waist',
      label: 'Waist Circumference',
      active: true,
    },
  ],
  resource: [
    {
      name: 'pre_signup_access',
      label: 'Access on pre-signup',
      active: true,
    },
  ],
  accessibility: [
    {
      name: 'skins',
      label: 'Skins',
      active: true,
    },
    {
      name: 'colorSaturation',
      label: 'Colour saturation',
      active: true,
    },
    {
      name: 'letterSpacing',
      label: 'Letter spacing',
      active: true,
    },
    {
      name: 'higlightLink',
      label: 'Highlight Link',
      active: true,
    },
    {
      name: 'lineheight',
      label: 'Line height',
      active: true,
    },
    {
      name: 'zoom',
      label: 'Zoom',
      active: true,
    },
    {
      name: 'bigCursor',
      label: 'Large cursor',
      active: true,
    },
    {
      name: 'fontSize',
      label: 'Font size',
      active: true,
    },
    {
      name: 'ruler',
      label: 'Ruler',
      active: true,
    },
    {
      name: 'readingMask',
      label: 'Reading mask',
      active: true,
    },
    {
      name: 'voiceNavigation',
      label: 'Voice Navigation',
      active: true,
    },
    {
      name: 'pageStructure',
      label: 'Page Structure',
      active: true,
    },
    {
      name: 'hideImage',
      label: 'Hide Image',
      active: true,
    },
    {
      name: 'helpGuide',
      label: 'Help Guide',
      active: true,
    },
    {
      name: 'dictionary',
      label: 'Dictionary',
      active: true,
    },
  ],
  fitbit: [
    {
      name: 'fitbitSleep',
      label: 'Sleep fitbit tracker',
      active: true,
    },
    {
      name: 'fitbitStep',
      label: 'Step fitbit tracker',
      active: true,
    },
  ],
  referral: [
    {
      name: 'pre_signup_access',
      label: 'Access on pre-signup',
      active: true,
    },
    {
      name: 'referral_share',
      label: 'Sharing referral among services',
      active: true,
    },
    {
      name: 'contributors',
      label: 'Adding contributors',
      active: true,
    },
  ],
  portalAdministration: [
    {
      name: 'role_management',
      label: 'Role management',
      active: true,
    },
    {
      name: 'user_management',
      label: 'User management',
      active: true,
    },
    {
      name: 'area_management',
      label: 'Area management',
      active: true,
    },
    {
      name: 'service_management',
      label: 'Service management',
      active: true,
    },
  ],
  notification: [
    {
      name: 'mail_inapp',
      label: 'Mail and In-App',
      active: true,
    },
    {
      name: 'sms',
      label: 'SMS',
      active: true,
    },
  ],
  caseload: [
    {
      name: 'team_caseload',
      label: 'Team caseload',
      active: true,
    },
    {
      name: 'patient_overview',
      label: 'Patient overview',
      active: true,
    },
    {
      name: 'best_interest_decisions',
      label: 'Best interest decisions',
      active: true,
    },
    {
      name: 'my_clinical_caseload',
      label: 'My clinical caseload',
      active: true,
    },
  ],
  card_tracker: true,
  card_connect: true,
  card_oauth: true,
  card_myHealth: true,
  card_resource: true,
  card_fitbit: true,
  card_accessibility: true,
  card_referral: true,
  card_administration: true,
  card_notification: true,
  card_caseload: true,
};
