import React from "react";
import ReactPlayers from "react-player";

interface ReactPlayerProps {
  url: string;
  addclass?: string;
  playing?: boolean | undefined
  width:string;
  height:string;
}

export const ReactPlayer: React.FC<ReactPlayerProps> = ({ url, addclass,playing,width,height }) => {
  return (
    <div>
      <ReactPlayers
        url={url}
        className={`react-player ${addclass}`}
        playing={playing}
        width={width}
        height={height}
        controls
      />
    </div>
  );
};
