import React from "react";
import "./styles/FormCheckBox.css";

// import { FormErrorMessage } from './FormErrorMessage';

export const FormCheckBox = ({
  name,
  id,
  label,
  type,
  register,
  rules,
  errors,
  isMobile,
  ...props
}: any): JSX.Element => {
  return (
    <div className="form-check flex gap-3 mb-2">
      <input
        id={id}
        className={
          "form-check-input" + (isMobile ? " form-check-input-mobile" : "")
        }
        type={type}
        {...(register && register(name, rules))}
        {...props}
      />
      <label className={`${type}-label`} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
