import React, { useEffect, useState } from 'react';

import {
  Account,
  BottomNav,
  BurgerMenu,
  HeaderSection,
  IUserInfo,
  Logo,
  Menu,
  MenuItem,
  MenuItemProps,
  NavNotification,
  PrimaryNav,
  RecordStatus,
  portalUrls,
} from 'mv-react-ui-components';

import useAuthContext from '../../context/provider/AuthContext';
import { appUrls } from '../../services/AppURLs';
import usePermissionContext from '../../context/provider/PermissionContext';

import { useNavigate, useLocation } from 'react-router-dom';
import HeaderImg from '../../assets/images/Maia_header.webp';

import { scopeNames } from '../../context/scopes/ScopesConstants';
import { MainPortalRolesType } from '../../context/type/UserRouterRoles';
import useViewportWidth from '../../utils/useViewportWidth';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logOutUser, userSelector } from '../../features/Auth/AuthSlice';
import { useHeadingJson } from './useHeadingJson';
import { useSeoJsonData } from './useSeoJsonData';

interface HeaderNavProps {
  headerRef?: any;
}

interface IBurgerMenu {
  name?: string;
  label?: string;
  link?: string;
  roles?: Array<string>;
  isActive?: boolean;
  isHide?: boolean;
  action?: () => void;
  children?: Array<IBurgerMenu>;
}

const HeaderNav: React.FC<HeaderNavProps> = ({ headerRef }) => {
  const {
    state: { user, isAuthenticated, globalSettings },
  } = useAuthContext();
  const [userInfo, setUserInfo] = useState<any>();
  let { trustLogo } = useAppSelector(userSelector);

  // console.log('userInfo', userInfo);

  useEffect(() => {
    if (user) setUserInfo(user);
  }, [user]);

  const regionalId = userInfo?.regionalId;

  const useHeading = useHeadingJson(user);
  const useSeoJson = useSeoJsonData(user);

  // console.log('decoded', decoded);

  const [showBento, setShowBento] = useState(true);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const { checkPermissions } = usePermissionContext();
  const [isMobile, setisMobile] = useState(false);

  const viewportWidth = useViewportWidth();
  const location = useLocation();

  useEffect(() => {
    if (viewportWidth <= 1023) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }, [viewportWidth]);

  // console.log('userInfo', regionalInfo);

  useEffect(() => {
    if (!showBento) {
      setShowProfileDropdown(false);
    }
  }, [showBento]);

  useEffect(() => {
    if (showProfileDropdown) {
      setShowBento(true);
    }
  }, [showProfileDropdown]);

  // Common Header Text Start
  const [openTab, setOpenTab] = useState(false);
  const [openBurgerTab, setOpenBurgerTab] = useState(false);
  const [dropStatus, setDropStatus] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [openProfileTab, setOpenProfileTab] = useState(false);

  const navigate = useNavigate();

  const handleLogoUrl = (role: string | undefined) => {
    if (!role) return '/';
    switch (role) {
      case MainPortalRolesType.mAdmin:
        return `${process.env.REACT_APP_MAIA_PORTAL_URL}${portalUrls.mainPortalUrls.dashboard.madmin}`;
      case MainPortalRolesType.superAdmin:
        return `${process.env.REACT_APP_MAIA_PORTAL_URL}${portalUrls.mainPortalUrls.rbac.regionalAdmin.dashboard}/${regionalId}`;

      case MainPortalRolesType.admin:
        return `${process.env.REACT_APP_MAIA_PORTAL_URL}${portalUrls.mainPortalUrls.rbac.regionalAdmin.dashboard}/${regionalId}`;
      case MainPortalRolesType.patient:
        if (user?.isCarer) {
          return `${process.env.REACT_APP_MAIA_PORTAL_URL}${portalUrls.mainPortalUrls.dashboard.carer}`;
        }
        return `${process.env.REACT_APP_MAIA_PORTAL_URL}${portalUrls.mainPortalUrls.dashboard.patient}`;
      default:
        return `${process.env.REACT_APP_MAIA_PORTAL_URL}${portalUrls.mainPortalUrls.dashboard.default}`;
    }
  };

  const toggleNotificationDropdown = async (status: boolean) => {
    setDropStatus(status);
  };

  const handleMenuToggle = (status: boolean) => {
    setOpenTab(status);
  };

  const viewAllNotifications = () => {
    navigate(
      userInfo?.role === 'patient'
        ? portalUrls.mainPortalUrls.patient.patientNotifications
        : portalUrls.mainPortalUrls.clinician.notifications
    );
  };

  const toggleAccountDropdown = (status: boolean) => {
    setOpenProfileTab(status);
  };

  const handleBurgerMenuToggle = (status: boolean) => {
    setOpenBurgerTab(status);
  };

  const dispatch = useAppDispatch();

  const logout = async () => {
    dispatch(logOutUser());
    localStorage.clear();
    window.location.href = appUrls.outerDomainUrls.mainParent.logout;
  };

  // burgerMenu List start
  const adminMenu = [
    {
      name: 'Dashboard',
      appUrl: 'MainPortal',
      roles: [MainPortalRolesType.admin],
      link: portalUrls.mainPortalUrls.dashboard.default,
      children: [],
    },
    {
      name: 'Caseload',
      roles: [MainPortalRolesType.admin],
      children: [
        {
          label: 'Team caseload',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.caseLoad.admin.teamCaseload,
          isActive: false,
          isHide: false,
        },
        {
          label: 'Patient overview',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.caseLoad.admin.patientOverview,
          isActive: false,
          isHide: false,
        },
        {
          label: 'Best interest decisions',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.caseLoad.admin.bestInterest,
          isActive: false,
          isHide: false,
        },
        {
          label: 'My clinical caseload',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.caseLoad.admin.myClinicalCaseload,
          isActive: false,
          isHide: false,
        },
      ],
    },
    {
      name: 'Connect',
      roles: [MainPortalRolesType.admin],
      isHide: !checkPermissions([
        scopeNames.circle.circle_patient.view,
        scopeNames.circle.circle_clinician.view,
        scopeNames.circle.circle_admin.view,
        scopeNames.circle.circle_message_patient.view,
        scopeNames.circle.circle_message_clinician.view,
        scopeNames.circle.circle_message_admin.view,
      ]),
      children: [
        {
          label: 'Community',
          appUrl: 'MainPortal',
          link: checkPermissions([
            scopeNames.circle.circle_patient.view,
            scopeNames.circle.circle_clinician.view,
            scopeNames.circle.circle_admin.view,
          ])
            ? checkPermissions([scopeNames.circle.circle_patient.view])
              ? portalUrls.mainPortalUrls.circle.clinician.patients
              : portalUrls.mainPortalUrls.circle.clinician.colleague
            : portalUrls.mainPortalUrls.circle.clinician.messages,
          isActive: false,
          isPath: '/my-community/',
          isHide: false,
        },
        {
          label: 'People',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.admin.people.myProfessionalProfile,
          isActive: false,
          isPath: '/admin/people/',
          isHide: !checkPermissions([
            scopeNames.circle.circle_patient.view,
            scopeNames.circle.circle_clinician.view,
            scopeNames.circle.circle_admin.view,
          ]),
        },
      ],
    },
    {
      name: 'Notifications',
      roles: [MainPortalRolesType.admin],
      isHide: !checkPermissions([
        scopeNames.notification.email_and_inapp.view,
        scopeNames.notification.sms.view,
      ]),
      children: [
        {
          label: 'Overview',
          appUrl: 'MainPortal',
          link: checkPermissions([scopeNames.notification.email_and_inapp.view])
            ? portalUrls.mainPortalUrls.notifications.overView
            : portalUrls.mainPortalUrls.notifications.smsOverview,
          isActive: false,
          isPath: '/notifications/over-view',
          isHide: false,
        },
        {
          label: 'Create notification',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.notifications.createNotification,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.notification.email_and_inapp.create,
          ]),
        },
        {
          label: 'Scheduled notifications',
          appUrl: 'MainPortal',
          link: checkPermissions([
            scopeNames.notification.email_and_inapp.scheduleNotification,
            scopeNames.notification.email_and_inapp.recurrenceNotification,
          ])
            ? portalUrls.mainPortalUrls.notifications.scheduledNotifications
            : portalUrls.mainPortalUrls.notifications.smsScheduled,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.notification.email_and_inapp.scheduleNotification,
            scopeNames.notification.email_and_inapp.recurrenceNotification,
          ]),
        },
        {
          label: 'Notifications history',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.notifications.notificationsHistory,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.notification.email_and_inapp.view,
          ]),
        },
      ],
    },
    {
      name: 'Appointments',
      roles: [MainPortalRolesType.admin],
      isHide: !checkPermissions([
        scopeNames.appointment.clinic.view,
        scopeNames.appointment.clinic_location.view,
        scopeNames.appointment.appointment_type.view,
        scopeNames.appointment.manage_appointment.view,
        scopeNames.appointment.appointment_status.view,
        scopeNames.appointment.diagnosis_type.view,
      ]),

      children: [
        {
          label: 'Dashboard',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.admin.dashboard,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.appointment.clinic.view,
            scopeNames.appointment.clinic_location.view,
            scopeNames.appointment.appointment_type.view,
            scopeNames.appointment.manage_appointment.view,
            scopeNames.appointment.appointment_status.view,
            scopeNames.appointment.diagnosis_type.view,
          ]),
        },
        {
          label: 'Clinics',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.admin.allClinics,
          isActive: false,
          isHide: !checkPermissions([scopeNames.appointment.clinic.view]),
        },
        {
          label: 'Clinic location',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.admin.allClinicLocations,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.appointment.clinic_location.view,
          ]),
        },
        {
          label: 'All patients',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.admin.patients,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.appointment.manage_appointment.view,
          ]),
        },
        {
          label: 'Booked appointment',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.admin.amendBooking,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.appointment.manage_appointment.view,
          ]),
        },
        {
          label: 'Appointment type',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.admin.appointmentType,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.appointment.appointment_type.view,
          ]),
        },
        {
          label: 'Diagnosis type',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.admin.diagnosisType,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.appointment.diagnosis_type.view,
          ]),
        },
      ],
    },
    {
      name: 'Questionnaires',
      roles: [MainPortalRolesType.admin],
      link: appUrls.formAndQuestinnarie,
      isHide: !checkPermissions([
        scopeNames.questionnaire.questionnaire_form.view,
      ]),
      children: [],
    },
    {
      name: 'Referrals',
      appUrl: 'MainPortal',
      roles: [MainPortalRolesType.admin],
      link: portalUrls.mainPortalUrls.referral.admin.referralAdmin,
      children: [],
    },
  ];

  const patientMenu = [
    {
      name: 'Dashboard',
      appUrl: 'MainPortal',
      roles: [MainPortalRolesType.patient],
      link: portalUrls.mainPortalUrls.dashboard.patient,
      children: [],
    },
    {
      name: 'My health',
      roles: [MainPortalRolesType.patient],
      children: [
        {
          label: 'Overview',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.myhealth.healthOverview,
          isActive: false,
          isHide: false,
        },
        {
          label: 'About me',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.myhealth.aboutme,
          isActive: false,
          isHide: false,
        },
        {
          label: 'Advance care plan',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.myhealth.advanceCarePlan,
          isActive: false,
          isHide: false,
        },
        {
          label: 'Current health',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.myhealth.currentHealth.diagnoses,
          isActive: false,
          isPath: '/current-health',
          isHide: !globalSettings.modules.myHealth.conditions_and_medications,
        },
        {
          label: 'Appointment',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.patient.dashboard,
          isActive: false,
          isPath: '/appointment',
          isHide: false,
        },
        {
          label: 'Questionnaires',
          link: appUrls.formAndQuestinnarie,
          isActive: false,
          isHide: !globalSettings.modules.myHealth.questionnaires,
        },
        {
          label: 'Documents',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.myhealth.documents,
          isActive: false,
          isHide: false,
        },
        {
          label: 'Consultations and events',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.myhealth.encounters,
          isActive: false,
          isHide: false,
        },
        {
          label: 'Health history',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.myhealth.diagnosisHistory,
          isPath: '/health-history',
          isActive: false,
          isHide: !globalSettings.modules.myHealth.conditions_and_medications,
        },
        {
          label: 'Lifestyle',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.myhealth.lifeStyle.myHealthFactors,
          isActive: false,
          isPath: '/my-health/lifestyle/',
          isHide: !globalSettings.modules.myHealth.my_lifestyle,
        },
      ],
    },
    {
      name: 'Trackers',
      roles: [MainPortalRolesType.patient],
      children: [
        {
          label: 'Tracker overview',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.tracking.trackers.dashboard,
          isActive: false,
          isPath: '/trackers',
          isHide: !globalSettings.modules.card_tracker,
        },
        {
          label: 'Coping strategies',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.tracking.coping,
          isActive: false,
          isHide: !globalSettings.modules.tracker.coping,
        },
        {
          label: 'Goals',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.tracking.goals,
          isActive: false,
          isHide: false,
        },
        {
          label: 'Diary',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.tracking.diary,
          isActive: false,
          isHide: !globalSettings.modules.tracker.diary,
        },
      ],
    },
    {
      name: 'Connect',
      roles: [MainPortalRolesType.patient],
      children: [
        {
          label: 'Connect overview',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.circle.connectOverview,
          isActive: false,
          isHide: false,
        },
        {
          label: 'Circle of care',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.circle.serviceUser.myClinicians,
          isActive: false,
          isPath: '/my-circle',
          isHide: false,
        },
        {
          label: 'Messages',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.circle.messages,
          isActive: false,
          isHide: false,
        },
        {
          label: 'Requests received',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.circle.requestRecieved,
          isActive: false,
          isPath: '/request-recieved',
          isHide: false,
        },
        {
          label: 'Requests sent',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.circle.requestSent,
          isActive: false,
          isHide: false,
        },
      ],
    },
    {
      name: 'Referrals',
      appUrl: 'MainPortal',
      roles: [MainPortalRolesType.patient],
      link: portalUrls.mainPortalUrls.referral.patient.pending,
      children: [],
    },
  ];

  const clinicianMenu = [
    {
      name: 'Dashboard',
      appUrl: 'MainPortal',
      roles: [MainPortalRolesType.clinician],
      link: '/dashboard/',
      children: [],
    },
    {
      name: 'Caseload',
      appUrl: 'MainPortal',
      roles: [MainPortalRolesType.clinician],
      children: [
        {
          label: 'Team caseload',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.caseLoad.admin.teamCaseload,
          isActive: false,
          isHide: false,
        },
      ],
    },
    {
      name: 'Connect',
      appUrl: 'MainPortal',
      roles: [MainPortalRolesType.clinician],
      isHide: !checkPermissions([
        scopeNames.circle.circle_patient.view,
        scopeNames.circle.circle_clinician.view,
        scopeNames.circle.circle_admin.view,
        scopeNames.circle.circle_message_patient.view,
        scopeNames.circle.circle_message_clinician.view,
        scopeNames.circle.circle_message_admin.view,
      ]),
      children: [
        {
          label: 'Circle of care',
          appUrl: 'MainPortal',
          link: checkPermissions([
            scopeNames.circle.circle_patient.view,
            scopeNames.circle.circle_clinician.view,
            scopeNames.circle.circle_admin.view,
          ])
            ? checkPermissions([scopeNames.circle.circle_patient.view])
              ? portalUrls.mainPortalUrls.circle.clinician.patients
              : portalUrls.mainPortalUrls.circle.clinician.colleague
            : portalUrls.mainPortalUrls.circle.clinician.messages,
          isActive: false,
          isPath: '/my-community/',
          isHide: false,
        },
        {
          label: 'Directory',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.clinician.people.directory,
          isActive: false,
          isPath: '/directory/',
          isHide: !checkPermissions([
            scopeNames.circle.circle_clinician.view,
            scopeNames.circle.circle_admin.view,
          ]),
        },
      ],
    },
    {
      name: 'Notifications',
      appUrl: 'MainPortal',
      roles: [MainPortalRolesType.clinician],
      isHide: !checkPermissions([
        scopeNames.notification.email_and_inapp.view,
        scopeNames.notification.sms.view,
      ]),
      children: [
        {
          label: 'Email & in-app',
          appUrl: 'MainPortal',
          link: checkPermissions([scopeNames.notification.email_and_inapp.view])
            ? portalUrls.mainPortalUrls.notifications.overView
            : portalUrls.mainPortalUrls.notifications.smsOverview,
          isActive: false,
          isPath: '/notifications/over-view',
          isHide: false,
        },
        {
          label: 'SMS',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.notifications.smsOverview,
          isActive: false,
          isHide: !checkPermissions([scopeNames.notification.sms.view]),
        },
        {
          label: 'Scheduled notifications',
          appUrl: 'MainPortal',
          link: checkPermissions([
            scopeNames.notification.email_and_inapp.scheduleNotification,
            scopeNames.notification.email_and_inapp.recurrenceNotification,
          ])
            ? portalUrls.mainPortalUrls.notifications.scheduledNotifications
            : portalUrls.mainPortalUrls.notifications.smsScheduled,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.notification.email_and_inapp.scheduleNotification,
            scopeNames.notification.email_and_inapp.recurrenceNotification,
          ]),
        },
        {
          label: 'Notifications history',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.notifications.notificationsHistory,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.notification.email_and_inapp.view,
          ]),
        },
      ],
    },
    {
      name: 'Appointment',
      appUrl: 'MainPortal',
      roles: [MainPortalRolesType.clinician],
      isHide: !checkPermissions([
        scopeNames.appointment.clinic.view,
        scopeNames.appointment.manage_appointment.view,
      ]),
      children: [
        {
          label: 'Dashboard',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.clinician.dashboard,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.appointment.clinic.view,
            scopeNames.appointment.manage_appointment.view,
          ]),
        },
        {
          label: `Clinics`,
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.clinician.newClinic,
          isPath: '/all-clinics',
          isActive: false,
          isHide: !checkPermissions([scopeNames.appointment.clinic.view]),
        },
        {
          label: 'Clinic locations',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.admin.allClinicLocations,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.appointment.clinic_location.view,
          ]),
        },
        {
          label: `All patients`,
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.clinician.patients,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.appointment.manage_appointment.view,
          ]),
        },
        {
          label: 'Booked appointment',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.admin.amendBooking,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.appointment.manage_appointment.view,
          ]),
        },
        {
          label: 'Appointment type',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.admin.appointmentType,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.appointment.appointment_type.view,
          ]),
        },
        {
          label: 'Diagnosis type',
          appUrl: 'MainPortal',
          link: portalUrls.mainPortalUrls.appointment.admin.diagnosisType,
          isActive: false,
          isHide: !checkPermissions([
            scopeNames.appointment.diagnosis_type.view,
          ]),
        },
      ],
    },
    {
      name: 'Questionnaires',
      appUrl: 'MainPortal',
      roles: [MainPortalRolesType.clinician],
      link: appUrls.formAndQuestinnarie,
      isHide: !checkPermissions([
        scopeNames.questionnaire.questionnaire_form.view,
      ]),
      children: [],
    },
  ];

  const resourceMenu = [
    {
      name: 'Resources',
      appUrl: 'MainPortal',
      roles: [
        MainPortalRolesType.patient,
        MainPortalRolesType.clinician,
        MainPortalRolesType.admin,
      ],
      link: portalUrls.mainPortalUrls.resources.allResources,
      children: [],
    },
  ];

  const resourcesHubMenu = [
    {
      name: 'Resource hub',
      roles: [
        MainPortalRolesType.clinician,
        MainPortalRolesType.admin,
        MainPortalRolesType.superAdmin,
      ],
      isHide: !checkPermissions([
        scopeNames.resource_hub.resource_management.view,
      ]),
      action: () => {},
      children: [],
    },
  ];

  const superAdminMenu = [
    {
      name: 'Dashboard',
      roles: [MainPortalRolesType.superAdmin],
      link: portalUrls.mainPortalUrls.rbac.regionalAdmin.dashboard,
      appUrl: 'MainPortal',
      children: [],
    },
    checkPermissions(scopeNames.service_management.view) && {
      roles: [MainPortalRolesType.superAdmin],
      link: `${portalUrls.mainPortalUrls.rbac.regionalAdmin.serviceOverview}/${regionalId}`,
      name: 'Service Management',
      appUrl: 'MainPortal',
      children: [],
    },
    checkPermissions(scopeNames.role_management.view) && {
      roles: [MainPortalRolesType.superAdmin],
      link: `${portalUrls.mainPortalUrls.rbac.regionalAdmin.regionalManageRoles}/${regionalId}`,
      name: 'Roll Management',
      appUrl: 'MainPortal',
      children: [],
    },
    checkPermissions(scopeNames.user_management.view) && {
      roles: [MainPortalRolesType.superAdmin],
      link: `${portalUrls.mainPortalUrls.rbac.regionalAdmin.regionalManageAllUsers}/${regionalId}`,
      name: 'User management',
      appUrl: 'MainPortal',
      children: [],
    },
    globalSettings.modules.card_resource && {
      roles: [MainPortalRolesType.superAdmin],
      link: portalUrls.mainPortalUrls.resources.allResources,
      name: 'Resource',
      appUrl: 'MainPortal',
      children: [],
    },
  ];

  const mAdminMenu = [
    {
      name: 'Dashboard',
      roles: [MainPortalRolesType.mAdmin],
      to: portalUrls.mainPortalUrls.rbac.mediwaveAdmin.dashboard,
      appUrl: 'MainPortal',
      children: [],
    },
    {
      name: 'Area management',
      roles: [MainPortalRolesType.mAdmin],
      link: portalUrls.mainPortalUrls.rbac.mediwaveAdmin.regional,
      appUrl: 'MainPortal',
      children: [],
    },
    {
      name: 'Theme management',
      roles: [MainPortalRolesType.mAdmin],
      link: portalUrls.mainPortalUrls.theme.themeSettings,
      appUrl: 'MainPortal',
      children: [],
    },
  ];

  const [burgerMenu, setBurgerMenu] = useState<Array<IBurgerMenu>>([
    ...adminMenu,
    ...patientMenu,
    ...clinicianMenu,
    ...superAdminMenu,
    ...mAdminMenu,
    ...resourceMenu,
    ...resourcesHubMenu,
  ]);
  // burgerMenu List End

  const PrimaryNavItem: MenuItem[] = [
    ...(isAuthenticated && userInfo?.role === MainPortalRolesType.clinician
      ? [
          {
            link:
              userInfo?.role === MainPortalRolesType.clinician
                ? portalUrls.mainPortalUrls.caseLoad.clinician.clinicalCaseload
                : portalUrls.mainPortalUrls.caseLoad.admin.teamCaseload,
            label: 'Caseload',
            isActive: false,
            isHide: false,
          },
        ]
      : []),

    ...(isAuthenticated && userInfo?.role === MainPortalRolesType.patient
      ? [
          globalSettings.modules.card_myHealth && {
            link: portalUrls.mainPortalUrls.myhealth.healthOverview,
            label: 'My health',
            isActive: false,
            isHide: false,
            appUrl: 'MainPortal',
          },
          globalSettings.modules.card_tracker && {
            link: portalUrls.mainPortalUrls.tracking.trackers.dashboard,
            label: 'Trackers',
            isActive: false,
            isHide: false,
            appUrl: 'MainPortal',
          },
          globalSettings.modules.card_referral && {
            link: portalUrls.mainPortalUrls.referral.patient.pending,
            label: 'Referrals',
            isActive: false,
            isHide: false,
            appUrl: 'MainPortal',
          },
        ]
      : []),

    ...(isAuthenticated &&
    ((userInfo &&
      globalSettings.modules.card_connect &&
      userInfo.role === MainPortalRolesType.patient) ||
      (userInfo &&
        userInfo.role !== MainPortalRolesType.superAdmin &&
        userInfo.role !== MainPortalRolesType.mAdmin &&
        checkPermissions([
          scopeNames.circle.circle_patient.view,
          scopeNames.circle.circle_clinician.view,
          scopeNames.circle.circle_admin.view,
          scopeNames.circle.circle_message_patient.view,
          scopeNames.circle.circle_message_clinician.view,
          scopeNames.circle.circle_message_admin.view,
        ])))
      ? [
          globalSettings.modules.card_connect && {
            link:
              userInfo.role === MainPortalRolesType.patient
                ? portalUrls.mainPortalUrls.circle.connectOverview
                : userInfo.role === MainPortalRolesType.admin
                ? checkPermissions([
                    scopeNames.circle.circle_patient.view,
                    scopeNames.circle.circle_clinician.view,
                    scopeNames.circle.circle_admin.view,
                  ])
                  ? checkPermissions([scopeNames.circle.circle_patient.view])
                    ? portalUrls.mainPortalUrls.circle.clinician.patients
                    : portalUrls.mainPortalUrls.circle.clinician.colleague
                  : portalUrls.mainPortalUrls.circle.clinician.messages
                : userInfo.role === MainPortalRolesType.clinician
                ? checkPermissions([
                    scopeNames.circle.circle_patient.view,
                    scopeNames.circle.circle_clinician.view,
                    scopeNames.circle.circle_admin.view,
                  ])
                  ? checkPermissions([scopeNames.circle.circle_patient.view])
                    ? portalUrls.mainPortalUrls.circle.clinician.patients
                    : portalUrls.mainPortalUrls.circle.clinician.colleague
                  : portalUrls.mainPortalUrls.circle.clinician.messages
                : portalUrls.mainPortalUrls.circle.serviceUser.myCarers,
            label: 'Connect',
            isActive: false,
            isHide: false,
            appUrl: 'MainPortal',
          },
        ]
      : []),

    ...(isAuthenticated &&
    userInfo &&
    userInfo.role !== MainPortalRolesType.patient &&
    userInfo.role !== MainPortalRolesType.clinician &&
    userInfo.role !== MainPortalRolesType.mAdmin &&
    checkPermissions([
      scopeNames.notification.email_and_inapp.view,
      scopeNames.notification.sms.view,
    ])
      ? [
          {
            link: checkPermissions([
              scopeNames.notification.email_and_inapp.view,
            ])
              ? portalUrls.mainPortalUrls.notifications.overView
              : portalUrls.mainPortalUrls.notifications.smsOverview,
            label: 'Notifications',
            isActive: false,
            isHide: false,
            appUrl: 'MainPortal',
          },
        ]
      : []),

    ...(isAuthenticated &&
    userInfo &&
    ((userInfo.role === MainPortalRolesType.admin &&
      checkPermissions([
        scopeNames.appointment.clinic.view,
        scopeNames.appointment.clinic_location.view,
        scopeNames.appointment.appointment_type.view,
        scopeNames.appointment.manage_appointment.view,
        scopeNames.appointment.appointment_status.view,
        scopeNames.appointment.diagnosis_type.view,
      ])) ||
      (userInfo.role === MainPortalRolesType.clinician &&
        checkPermissions([
          scopeNames.appointment.clinic.view,
          scopeNames.appointment.manage_appointment.view,
        ])))
      ? [
          globalSettings.modules.myHealth.appointment && {
            link:
              userInfo.role === MainPortalRolesType.patient
                ? portalUrls.mainPortalUrls.appointment.patient.dashboard
                : portalUrls.mainPortalUrls.appointment.admin.dashboard,
            label: 'Appointments',
            isActive: false,
            isHide: false,
            appUrl: 'MainPortal',
          },
        ]
      : []),

    ...(isAuthenticated &&
    userInfo &&
    (userInfo.role === MainPortalRolesType.clinician ||
      userInfo.role === MainPortalRolesType.mAdmin ||
      userInfo.role === MainPortalRolesType.admin ||
      userInfo.role === MainPortalRolesType.superAdmin)
      ? [
          checkPermissions([
            scopeNames.questionnaire.questionnaire_form.view,
          ]) && {
            link: appUrls.formAndQuestinnarie,
            label: 'Questionnaires',
            isActive: false,
            isHide: false,
          },
        ]
      : []),

    ...(isAuthenticated &&
    globalSettings.modules.card_resource &&
    userInfo &&
    userInfo.role !== MainPortalRolesType.mAdmin
      ? [
          {
            link: portalUrls.mainPortalUrls.resources.allResources,
            label: 'Resources',
            isActive: false,
            isHide: false,
            appUrl: 'MainPortal',
          },
        ]
      : []),

    ...(isAuthenticated &&
    userInfo &&
    userInfo.role !== MainPortalRolesType.mAdmin
      ? [
          globalSettings.modules.referral &&
            checkPermissions([
              scopeNames.referral.referral_management.view,
            ]) && {
              link:
                userInfo.role === MainPortalRolesType.patient
                  ? portalUrls.mainPortalUrls.referral.patient.pending
                  : checkPermissions([
                      scopeNames.referral.referral_management.triage,
                    ])
                  ? portalUrls.mainPortalUrls.referral.admin.referralAdmin
                  : portalUrls.mainPortalUrls.referral.admin.serviceAdmin,
              label: 'Referrals',
              isActive: false,
              isHide: false,
              appUrl: 'MainPortal',
            },
        ]
      : []),
  ];

  const bentoMenuItems: MenuItemProps[] = [
    ...(isAuthenticated && userInfo?.role === MainPortalRolesType.clinician
      ? [
          {
            to: portalUrls.mainPortalUrls.dashboard.default,
            label: 'Dashboard',
            appUrl: 'MainPortal',
          },
          checkPermissions([
            scopeNames.circle.circle_patient.view,
            scopeNames.circle.circle_clinician.view,
            scopeNames.circle.circle_admin.view,
            scopeNames.circle.circle_message_patient.view,
            scopeNames.circle.circle_message_clinician.view,
            scopeNames.circle.circle_message_admin.view,
          ]) && {
            to: checkPermissions([
              scopeNames.circle.circle_patient.view,
              scopeNames.circle.circle_clinician.view,
              scopeNames.circle.circle_admin.view,
            ])
              ? checkPermissions([scopeNames.circle.circle_patient.view])
                ? portalUrls.mainPortalUrls.circle.clinician.patients
                : portalUrls.mainPortalUrls.circle.clinician.colleague
              : portalUrls.mainPortalUrls.circle.clinician.messages,
            label: 'Circle of care',
            appUrl: 'MainPortal',
          },
          globalSettings.modules.card_resource && {
            to: portalUrls.mainPortalUrls.resources.allResources,
            label: 'Resources',
            appUrl: 'MainPortal',
          },
        ]
      : []),
    ...(isAuthenticated && userInfo?.role === MainPortalRolesType.patient
      ? [
          {
            to: portalUrls.mainPortalUrls.dashboard.patient,
            label: 'Dashboard',
            appUrl: 'MainPortal',
          },
          {
            to: portalUrls.mainPortalUrls.circle.serviceUser.myClinicians,
            label: 'Circle of care',
            appUrl: 'MainPortal',
          },
          {
            to: portalUrls.mainPortalUrls.appointment.patient.dashboard,
            label: 'Appointments',
            appUrl: 'MainPortal',
          },
          {
            label: 'Questionnaires',
            to: appUrls.formAndQuestinnarie,
          },
          globalSettings.modules.card_resource && {
            to: portalUrls.mainPortalUrls.resources.allResources,
            label: 'Resources',
            appUrl: 'MainPortal',
          },
        ]
      : []),
    ...(isAuthenticated &&
    (userInfo?.role === MainPortalRolesType.superAdmin ||
      userInfo?.role === MainPortalRolesType.admin)
      ? [
          {
            to: portalUrls.mainPortalUrls.rbac.regionalAdmin.dashboard,
            label: 'Dashboard',
            appUrl: 'MainPortal',
          },
          checkPermissions(scopeNames.service_management.view) && {
            to: `${portalUrls.mainPortalUrls.rbac.regionalAdmin.serviceOverview}/${regionalId}`,
            label: 'Service Management',
            appUrl: 'MainPortal',
          },
          checkPermissions(scopeNames.role_management.view) && {
            to: `${portalUrls.mainPortalUrls.rbac.regionalAdmin.regionalManageRoles}/${regionalId}`,
            label: 'Roll Management',
            appUrl: 'MainPortal',
          },
          checkPermissions(scopeNames.user_management.view) && {
            to: `${portalUrls.mainPortalUrls.rbac.regionalAdmin.regionalManageAllUsers}/${regionalId}`,
            label: 'User management',
            appUrl: 'MainPortal',
          },
          globalSettings.modules.card_resource && {
            to: portalUrls.mainPortalUrls.resources.allResources,
            label: 'Resources',
            appUrl: 'MainPortal',
          },
        ]
      : []),
    ...(isAuthenticated && userInfo?.role === MainPortalRolesType.mAdmin
      ? [
          {
            to: portalUrls.mainPortalUrls.rbac.mediwaveAdmin.dashboard,
            label: 'Dashboard',
            appUrl: 'MainPortal',
          },
          {
            to: portalUrls.mainPortalUrls.theme.themeSettings,
            label: 'Theme management',
            appUrl: 'MainPortal',
          },
          {
            to: portalUrls.mainPortalUrls.rbac.mediwaveAdmin.regional,
            label: 'Area management',
            appUrl: 'MainPortal',
          },
        ]
      : []),
    ...(isAuthenticated &&
    (userInfo?.role === MainPortalRolesType.clinician ||
      userInfo?.role === MainPortalRolesType.admin ||
      userInfo?.role === MainPortalRolesType.superAdmin)
      ? [
          checkPermissions([
            scopeNames.resource_hub.resource_management.view,
          ]) && {
            label: 'Resource hub',
            action: () => {},
          },
        ]
      : []),
  ];

  const profileMenuItems: MenuItemProps[] = [
    {
      label: 'Account',
      to: portalUrls.mainPortalUrls.settings.accountSettings,
      appUrl: 'MainPortal',
    },
    {
      label: 'Logout',
      to: appUrls.outerDomainUrls.mainParent.logout,
      action: () => {
        logout();
      },
    },
  ];

  return (
    <>
      <div>
        <header
          role='banner'
          className={`${
            isMobile && location.pathname !== '/' ? 'mobile-navbar' : ''
          }`}
          ref={headerRef}
        >
          <HeaderSection
            brandName='AsOneHealth'
            isAuthenticated={isAuthenticated}
            useHeadingJson={useHeading}
            useSeoJsonData={useSeoJson}
            backgroundImageUrl={HeaderImg}
          >
            <nav className='nav-group'>
              <div className='just-w flex w-[188px] gap-4'>
                <BurgerMenu
                  openTab={openBurgerTab}
                  changeAction={handleBurgerMenuToggle}
                  MainPortal={process.env.REACT_APP_MAIA_PORTAL_URL}
                  menuData={
                    burgerMenu.filter(
                      (menuFor: any) =>
                        userInfo &&
                        userInfo?.role &&
                        Array.isArray(menuFor?.roles) &&
                        menuFor?.roles.includes(userInfo?.role)
                    ) || ''
                  }
                  LogoUrl={trustLogo || ''}
                />
                <Logo
                  isLoading
                  imageUrl={trustLogo}
                  link={handleLogoUrl(userInfo?.role)}
                />
              </div>
              <PrimaryNav
                navItems={PrimaryNavItem}
                MainPortal={process.env.REACT_APP_MAIA_PORTAL_URL}
              />
              <div className='flex items-center gap-4 lg:gap-10'>
                <Menu
                  MainPortal={process.env.REACT_APP_MAIA_PORTAL_URL}
                  menuItems={bentoMenuItems.filter(
                    (item: any) => item !== false
                  )}
                  changeAction={handleMenuToggle}
                  openTab={openTab}
                />
                {userInfo && userInfo?.role !== MainPortalRolesType.mAdmin && (
                  <NavNotification
                    dropStatus={dropStatus}
                    openNotify={toggleNotificationDropdown}
                    goNotifications={viewAllNotifications}
                    totalNotifications={totalNotifications}
                  >
                    {totalNotifications > 0 ? (
                      <>
                        {/* {list.map((data: any, index: any) => (
                            <div key={index} className="message">
                              {data.notification_schedule.notification_type !==
                                'in_app' &&
                              data.notification_schedule.notification_type !==
                                'both' &&
                              !data.notification_schedule.notification_type.includes(
                                '_normal'
                              ) ? (
                                <NotifyCard
                                  content={titleHandler(data)}
                                  time={moment(data.time).fromNow()}
                                />
                              ) : (
                                <NotifyCard
                                  content={titleHandler(data)}
                                  addClass="cursor-default"
                                  time={moment(data.time).fromNow()}
                                />
                              )}
                            </div>
                          ))} */}
                      </>
                    ) : (
                      <RecordStatus label='No data found!' />
                    )}
                  </NavNotification>
                )}
                <Account
                  userInfo={userInfo}
                  menuItems={profileMenuItems}
                  openTab={openProfileTab}
                  changeAction={toggleAccountDropdown}
                  MainPortal={process.env.REACT_APP_MAIA_PORTAL_URL}
                />
              </div>
            </nav>
          </HeaderSection>
        </header>
        <BottomNav
          MainPortal={process.env.REACT_APP_MAIA_PORTAL_URL}
          navItems={PrimaryNavItem.slice(0, 4)}
        />
      </div>
    </>
  );
};

export const CommonHeader = React.memo(HeaderNav);
