import React from "react";
import tagClose from "../assets/images/tag-close.png";

import "./styles/Tags.css";

export const Tags = ({ ...props }: any): JSX.Element => {
  return (
    <div
      className="tags items-center inline-flex fw-bold me-3 mb-3"
      key={props.customKey}
    >
      <span className="me-3">{props.name}</span>
      <span
        className="cursor-pointer"
        onClick={() => props.removeTags(props.customKey)}
      >
        <img src={tagClose} alt="tag-close" />
      </span>
    </div>
  );
};

export default Tags;
