import React from "react";
// import ReactSelect from "react-select";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FormErrorMessage } from "./FormErrorMessage";

import "./styles/FormSelect.css";
import { getRandomText } from "../global/HelperFunctions";

export const FormSelect = ({
  name,
  register,
  rules,
  errors,
  className,
  label,
  field,
  options,
  valueKey,
  labelKey,
  control,
  linkText,
  isSearchable,
  id = getRandomText(10),
  selectedValue,
  ...props
}: any): JSX.Element => {
  const tabIndex = 0;
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minHeight: "48px",
      borderColor: "rgba(var(--outline) / 0.5)",
      border: "1px solid",
      boxShadow: "none",
      cursor: "pointer",
      "&:hover": {
        border: "2px solid",
        color: "rgba(var(--primary) / 1)",
        borderColor: "rgba(var(--primary) / 1)",
      },
      "&:active": {
        border: "2px solid",
        borderColor: "rgba(var(--primary) / 1)",
      },
      borderRadius: "8px",
    }),
    placeholder: (base: any, state: any) => ({
      ...base,
      color: "#6c757d",
      fontsize: "16px",
      padding: "0",
    }),
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      color: "rgba(var(--baseColor) / 1)",
      paddingRight: "16px",
    }),
    indicatorSeparator: (base: any, state: any) => ({
      ...base,
      background: "none",
    }),
    valueContainer: (base: any, state: any) => ({
      ...base,
      paddingLeft: "12px",
      padding: "0",
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      zIndex: "9999",
      // kill the gap
      // marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: "rgba(var(--primary) / 0.2) !important",
      zIndex: "9999",
    }),
    option: (base) => ({
      ...base,
      textOverflow: "ellipsis",
      overflow: "hidden",
      backgroundColor: "rgba(var(--neutral) / 1) !important",
      color: "rgba(var(--baseColor) / 1) !important",
      zIndex: "9999",

      "&:hover": {
        color: "rgba(var(--baseColor) / 1) !important",
        backgroundColor: "rgba(var(--primary) / 0.2) !important",
      },
    }),
  };
  return (
    <div className="custom-select">
      <div className="input-group">
        <label htmlFor={id}>{label}</label>
      </div>
      <Controller
        name={name}
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          return (
            <Select
              // menuIsOpen
              isSearchable={isSearchable}
              inputId={id}
              styles={customStyles}
              className="react-select-container select-placeholder"
              value={options.find((c: any) => c[valueKey] === value)}
              getOptionLabel={(option: any) => `${option[labelKey]}`}
              getOptionValue={(option: any) => `${option[valueKey]}`}
              defaultValue={options.find(
                (c: any) => c[valueKey] === selectedValue
              )}
              onChange={(val: any) => {
                console.log("🚀 ~ file: FormSelect.tsx:111 ~ val:", val);
                console.log(val[valueKey]);
                onChange(val[valueKey]);
                if (props.onSelectChange) {
                  props.onSelectChange(val[valueKey], val);
                }
              }}
              ref={ref}
              options={options || []}
              {...props}
            />
          );
        }}
        control={control}
        rules={rules}
      />
      <ErrorMessage
        errors={errors}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name={name as any}
        render={({ message }) => (
          <FormErrorMessage className="mt-1">{message}</FormErrorMessage>
        )}
      />
      {/* <FormErrorMessage className="mt-1">Error</FormErrorMessage> */}
      <div className="input-link">{linkText}</div>
    </div>
  );
};
