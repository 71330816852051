export const MainPortalRolesType = {
  superAdmin: 'Super Admin',
  admin: 'admin',
  clinician: 'clinician',
  user: 'user',
  patient: 'patient',
  carer: 'carer',
  family: 'family',
  teacher: 'teacher',
  mAdmin: 'MAdmin',
};
