import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import jwt from 'jwt-decode';
import { Constants } from '../global/Constants';
import { scopeProperty } from '../global/scopePayload';

import './styles/Header.css';

const Navbar = () => {
  const navigate = useNavigate();
  const onNavClick = (view: string) => {
    navigate(`${view}`);
  };

  const jwtDecode: any = jwt(localStorage.getItem('token') || '');
  const shortenRole: Array<string> = jwtDecode.scopePermissions || [];

  const userRoles = Constants.USER_ROLES;

  return (
    <>
      {userRoles && shortenRole ? (
        <nav role='navigation' className='sec-nav-wrap'>
          <ul>
            {shortenRole.includes('L1') && (
              <li role='presentation' className='sec-nav-item'>
                <NavLink
                  to='/trust'
                  className={({ isActive }) => (isActive ? 'activeMenu' : '')}
                >
                  Trust management
                </NavLink>
              </li>
            )}
            {shortenRole.includes(scopeProperty.ALL_ACCESS) &&
              !Object.keys(jwtDecode).includes('maiaScope') && (
                <>
                  <li role='presentation' className='sec-nav-item'>
                    <NavLink
                      to='/systemAdmin'
                      className={({ isActive }) =>
                        isActive ? 'activeMenu' : ''
                      }
                    >
                      Super Admin management
                    </NavLink>
                  </li>
                  <li role='presentation' className='sec-nav-item'>
                    <NavLink
                      to='/roles'
                      className={({ isActive }) =>
                        isActive ? 'activeMenu' : ''
                      }
                    >
                      Roles
                    </NavLink>
                  </li>
                </>
              )}
            {shortenRole.includes(scopeProperty.USER_CREATE) && (
              <li role='presentation' className='sec-nav-item'>
                <NavLink
                  to='/user-management'
                  className={({ isActive }) => (isActive ? 'activeMenu' : '')}
                >
                  User management
                </NavLink>
              </li>
            )}
            {shortenRole.includes(scopeProperty.RESOURCE_VIEW) && (
              <li role='presentation' className='sec-nav-item'>
                <NavLink
                  to='/resource'
                  className={({ isActive }) => (isActive ? 'activeMenu' : '')}
                  end
                >
                  Current resource list
                </NavLink>
              </li>
            )}
            {shortenRole.includes(scopeProperty.RESOURCE_CREATE) && (
              <li role='presentation' className='sec-nav-item'>
                <NavLink
                  to='/resource/create'
                  className={({ isActive }) => (isActive ? 'activeMenu' : '')}
                  end
                >
                  Create new resource
                </NavLink>
              </li>
            )}

            <li role='presentation' className='sec-nav-item'>
              <NavLink
                to='/notification-centre'
                className={({ isActive }) => (isActive ? 'activeMenu' : '')}
              >
                My notifications
              </NavLink>
            </li>
            {(shortenRole.includes(scopeProperty.RESOURCE_PUBLISHED_VIEW) ||
              (shortenRole.includes(scopeProperty.ALL_ACCESS) &&
                Object.keys(jwtDecode).includes('maiaScope'))) && (
              <li role='presentation' className='sec-nav-item'>
                <NavLink
                  to='/resource-library'
                  className={({ isActive }) => (isActive ? 'activeMenu' : '')}
                >
                  Resource library
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      ) : (
        ''
      )}
    </>
  );
};

export default Navbar;
