import React, { useState, useEffect, forwardRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useAppDispatch } from '../../app/hooks';
import './Resource.css';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../assets/images/calendar-icon.svg';
import Close from '../../assets/images/close-01.svg';

const ResourceFilter = ({
  closeFilter,
  handleFilter,
  defaultValues,
  resourceTypes,
  resourceTopics,
  authorsList,
  tagList,
}: {
  closeFilter: () => void;
  handleFilter: (data: any) => void;
  defaultValues: any;
  resourceTypes: any;
  resourceTopics: any;
  authorsList: any;
  tagList: any;
}) => {
  const tabIndex = 0;
  const [typesToShow, setTypesToShow] = useState(8);
  const [expandedTypes, setExpandedTypes] = useState(false);
  const [expandedTags, setExpandedTags] = useState(false);
  const [topicsToShow, setTopicsToShow] = useState(8);
  const [tagsToShow, setTagsToShow] = useState(8);
  const [expandedTopics, setExpandedTopics] = useState(false);
  const [authorsToShow, setAuthorsToShow] = useState(8);
  const [expandedAuthors, setExpandedAuthors] = useState(false);

  const ages = [
    { label: 'Adult', value: 'Adult' },
    { label: 'CYP', value: 'CYP' },
    { label: 'Older Adults', value: 'Older Adults' },
    { label: 'Paediatrics', value: 'Paediatrics' },
    { label: 'Child (0-18)', value: 'Child (0-18)' },
    { label: 'Over 18', value: 'Over 18' },
    { label: 'Adult (19 – 64)', value: 'Adult (19 – 64)' },
    { label: 'Elderly (65+)', value: 'Elderly (65+)' },
  ];

  const {
    register,
    handleSubmit,
    trigger,
    control,
    watch,
    resetField,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const fromDate = watch('fromReviewDate');
  const toDate = watch('toReviewDate');
  const isStartDate = watch('fromReviewDate'); // Get the value of the other field

  const resetDate = (e) => {
    e.preventDefault();
    resetField('fromReviewDate');
    resetField('toReviewDate');
  };

  useEffect(() => {
    // const multipleValues = getValues("fromReviewDate");
    // console.log(multipleValues)
    trigger();
  }, [trigger]);

  const onSubmit = (data: any) => {
    handleFilter(data);
  };

  const clearFilter = (e: any) => {
    e.preventDefault();
    reset({
      type: false,
      topic: false,
      ageGroup: false,
      tags: false,
      fromReviewDate: false,
      toReviewDate: false,
      trustListToFetch: false,
    });
  };

  const CustomDatePicker = forwardRef(({ value, onClick }: any, ref: any) => (
    <div
      tabIndex={tabIndex}
      className='custom-date-picker onkeyup cursor-pointer flex justify-between items-center w-full p-2'
      onClick={onClick}
      ref={ref}
    >
      <span className='ms-2'>{value}</span>
      <img src={calendarIcon} alt='calendar' />
    </div>
  ));

  const showMoreTypes = () => {
    setTypesToShow(resourceTypes.length);
    setExpandedTypes(true);
  };
  const showMoreTopics = () => {
    setTopicsToShow(resourceTopics.length);
    setExpandedTopics(true);
  };
  const showMoreAuthors = () => {
    setAuthorsToShow(authorsList.length);
    setExpandedAuthors(true);
  };

  const showMoreTags = () => {
    setTagsToShow(tagList.length);
    setExpandedTags(true);
  };

  return (
    <div className='filter-layout fixed top-0 right-0 z-[99999] h-screen bg-neutral   '>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex justify-between w-full max-w-full md:max-w-[608px] fixed top-0 items-center bg-navbar px-5 py-4'>
          <h2 className='text-navbarContent'>Filter</h2>

          <img
            src={Close}
            tabIndex={tabIndex}
            className='cursor-pointer focusto close-icon'
            id='focus_popup'
            alt='close'
            onClick={closeFilter}
          />
        </div>
        <div className='filter-content p-4 overflow-y-auto whitespace-nowrap pb-[110px] pt-[90px]'>
          <div className='grid gap-7'>
            <>
              {/* Resource Type */}
              <div className='block'>
                <h4 className='filter-header'>Resource Type</h4>
                <div
                  className={
                    !expandedTypes
                      ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 filter-resource-type gap-5'
                      : 'grid grid-cols-1 gap-5 filter-resource-type-scroll'
                  }
                >
                  {resourceTypes
                    .slice(0, typesToShow)
                    .map(
                      (type: { name: string; value: string }, index: any) => (
                        <React.Fragment key={index}>
                          <div className='flex items-center ' key={index}>
                            <input
                              className='me-2 filter-checkbox form-check-input'
                              type='checkbox'
                              value={
                                type.name === 'Events & Groups'
                                  ? 'Events%20%26%20Groups'
                                  : type.name
                              }
                              id={type.name}
                              {...(register && register('type'))}
                            />
                            <label
                              className='form-check-label filter-label'
                              htmlFor={type.name}
                            >
                              {type.name}
                            </label>
                          </div>
                          {!expandedTypes && index === 7 ? (
                            <div className='block'>
                              <label
                                tabIndex={tabIndex}
                                className='form-check-label filter-link onkeyup'
                                onClick={showMoreTypes}
                              >
                                Show more +
                              </label>
                            </div>
                          ) : (
                            ''
                          )}
                        </React.Fragment>
                      )
                    )}
                </div>
              </div>

              {/* Topic */}
              <div className='block'>
                <h4 className='filter-header'>Topic</h4>
                <div
                  className={
                    !expandedTopics
                      ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 filter-resource-type gap-5'
                      : 'grid grid-cols-1 gap-5 filter-resource-type-scroll'
                  }
                >
                  {resourceTopics
                    .slice(0, tagsToShow)
                    .map(
                      (topic: { name: string; value: string }, index: any) => (
                        <>
                          <div className='block' key={topic.name}>
                            <input
                              className='me-2 filter-checkbox form-check-input'
                              type='checkbox'
                              value={topic.name}
                              id={topic.name}
                              {...(register && register('topic'))}
                            />
                            <label
                              className='form-check-label filter-label'
                              htmlFor={topic.name}
                            >
                              {topic.name}
                            </label>
                          </div>
                          {!expandedTopics && index === 7 ? (
                            <div className='block'>
                              <label
                                tabIndex={tabIndex}
                                className='form-check-label filter-link onkeyup'
                                onClick={showMoreTopics}
                              >
                                Show more +
                              </label>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      )
                    )}
                </div>
              </div>
              {/* Age */}
              <div className='block'>
                <h4 className='filter-header'>Age</h4>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 filter-resource-type gap-5'>
                  {ages.map((ageGroup: { label: string; value: string }) => (
                    <div className='block' key={ageGroup.label}>
                      <input
                        className='me-2 filter-checkbox form-check-input'
                        type='checkbox'
                        value={ageGroup.value}
                        id={ageGroup.label}
                        {...(register && register('ageGroup'))}
                      />
                      <label
                        className='form-check-label filter-label'
                        htmlFor={ageGroup.label}
                      >
                        {ageGroup.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              {/* Tags */}
              <div className='block'>
                <h4 className='filter-header'>Tags</h4>
                <div
                  className={
                    !expandedTags
                      ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 filter-resource-type gap-5'
                      : 'grid grid-cols-1 gap-5 filter-resource-type-scroll'
                  }
                >
                  {tagList
                    .slice(0, tagsToShow)
                    .map(
                      (
                        selectedtags: { _id: string; name: string },
                        index: any
                      ) => (
                        <>
                          <div className='block' key={selectedtags._id}>
                            <input
                              className='me-2 filter-checkbox form-check-input'
                              type='checkbox'
                              value={selectedtags.name}
                              id={selectedtags._id}
                              {...(register && register('tags'))}
                            />
                            <label
                              className='form-check-label filter-label'
                              htmlFor={selectedtags._id}
                            >
                              {selectedtags.name}
                            </label>
                          </div>
                          {!expandedTags && index === 7 ? (
                            <div className='block'>
                              <label
                                tabIndex={tabIndex}
                                className='form-check-label filter-link onkeyup'
                                onClick={showMoreTags}
                              >
                                Show more +
                              </label>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      )
                    )}
                </div>
              </div>
              {/* Publish Date */}
              <div className='block'>
                <h4 className='filter-header'>Publish Date</h4>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 filter-resource-type gap-5 res-publish-date'>
                  <div className=' input-group'>
                    <label
                      tabIndex={tabIndex}
                      className='form-check-label filter-label'
                    >
                      From
                    </label>
                    <Controller
                      name='fromReviewDate'
                      render={({ field: { ref, value, onChange } }) => {
                        return (
                          <DatePicker
                            {...register('fromReviewDate')}
                            onChange={(fromDate) => {
                              setValue('toReviewDate', null);
                              // resetField("toReviewDate");
                              onChange(moment(fromDate).endOf('day').toDate());
                            }}
                            selected={value ? moment(value).toDate() : null}
                            ref={ref}
                            placeholderText='DD/MM/YYYY'
                            customInput={<CustomDatePicker />}
                            //minDate={moment().toDate()}
                            dateFormat='dd/MM/yyyy'
                          />
                        );
                      }}
                      control={control}
                    />
                  </div>

                  <div
                    className={` input-group ${
                      !getValues('fromReviewDate') ? 'date-disabled' : ''
                    }`}
                  >
                    <label
                      tabIndex={tabIndex}
                      className='form-check-label filter-label'
                    >
                      To
                    </label>
                    <Controller
                      name='toReviewDate'
                      control={control}
                      rules={{
                        required: isStartDate ? 'To date is required' : false, // Set to false when not required
                        validate: (value) => {
                          if (value) {
                            const startDate = new Date(
                              getValues('fromReviewDate')
                            );
                            const endDate = new Date(value);
                            return (
                              startDate <= endDate ||
                              'To date must be after from date'
                            );
                          }
                        },
                      }}
                      render={({ field: { ref, value, onChange } }) => {
                        return (
                          <DatePicker
                            onChange={(endDate) => {
                              onChange(moment(endDate).endOf('day').toDate());
                            }}
                            placeholderText='DD/MM/YYYY'
                            selected={value ? moment(value).toDate() : null}
                            ref={ref}
                            customInput={<CustomDatePicker />}
                            minDate={moment(
                              getValues('fromReviewDate')
                            ).toDate()}
                            dateFormat='dd/MM/yyyy'
                            disabled={!getValues('fromReviewDate')}
                          />
                        );
                      }}
                    />
                    {errors?.toReviewDate && (
                      <span className='' style={{ color: '#ff0000' }}>
                        {errors?.toReviewDate?.message}
                      </span>
                    )}
                  </div>
                  <div className='col-12 clear-btn d-none'>
                    {fromDate && toDate && (
                      <button onClick={(e) => resetDate(e)}>Clear</button>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
        <div className='filter-submit flex fixed bottom-0 w-full max-w-full md:max-w-[608px] justify-content-center !p-5'>
          <button className='btn btn-violet me-4' type='submit'>
            Save & Filter
          </button>
          <button className='btn btn-violet me-4' onClick={closeFilter}>
            Cancel
          </button>
          <button
            className='btn btn-violet me-4'
            onClick={(e: any) => clearFilter(e)}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResourceFilter;
