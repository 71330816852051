import React, { useState, useEffect } from 'react';

import { TabPanel } from 'react-tabs';
import CustomTabs from '../../components/tabs/CustomTabs';
import { useAppDispatch } from '../../app/hooks';
import { fetchPermissions } from '../../features/User/UserSlice';
import './roles.css';
import { FormInput } from '../../components/FormInput';
import { CustomModal } from '../../components/CustomModal';
import { AddIcon } from './AddIcon';
import { scopeAndModelsList } from '../../global/Constants';
import { Constants } from '../../global/Constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  createRole,
  getRoleAndScope,
  updateRole,
} from '../../features/roleAndScope/roleSlice';
import Loading from '../../components/loading/Loading';
import { Toaster, toast } from 'react-hot-toast';
import Toast from '../../components/Toast';
import helperFunctions from '../../global/HelperService';

interface RolesProps {}

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  new_scope: Yup.array().min(1, 'Required'),
});

const Roles: React.FC<RolesProps> = () => {
  const dispatch = useAppDispatch();
  const [AddRole, setAddRole] = useState(false);
  const [isSendScopeModelOpen, setSendScopeModelOpen] =
    useState<boolean>(false);
  const [tabValue, setTabValue] = useState<any>(0);
  const [roleTabValue, setRoleTabValue] = useState<any>(0);
  const [scopeAndModuleList, setScopeAndModuleList] = useState<any>({});
  const [loader, setloader] = useState(false);
  const [selectedScope, setSelectedScope] = useState<string[] | []>([]);
  const [roleName, setRoleName] = useState<string>('');
  const [role, setRole] = useState<any>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   console.log("------------------------------HIT- here---");
  //   fetchRoles()
  // }, []);

  // const fetchRoles = (requestQuery?: any) => {
  //   setloader(true);
  //   dispatch(
  //     fetchPermissions({})
  //   )
  //     .unwrap()
  //     .then((data: any) => {
  //       console.log("🚀 ~ file: ResourceForm.tsx:211 ~ .then ~ data:", data.data[0].name)
  //       setListOfRoles(data.data);
  //       setloader(false);
  //     })
  //     .catch((e) => {
  //       console.log("error---", e);
  //       setloader(false);
  //     });
  // };

  const handleTabValueFromScopeList = (
    tabValue: number,
    moduleName: string | undefined
  ) => {
    if (moduleName) {
      return scopeAndModelsList[Object.keys(scopeAndModelsList)[tabValue]][
        moduleName
      ];
    }
    return Object.keys(
      scopeAndModelsList[Object.keys(scopeAndModelsList)[tabValue]]
    );
  };

  const handleScopeAndModule = (scope: string) => {
    if (scope && scope !== 'all') {
      let titleScope = scope.split(':')[0].split('-')[0];
      let capletter = titleScope.charAt(0).toUpperCase();
      titleScope = titleScope.slice(1);
      titleScope = capletter + titleScope;
      let subTitle = scope
        .split(':')
        .toString()
        .toUpperCase()
        .replaceAll('-', ',')
        .replaceAll(',', '_');
      return scopeAndModelsList[`${titleScope} management`][subTitle];
    }

    {
      return '';
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedScopeId: '',
      name: '',
      previous_scope: [],
      new_scope: [],
      tpId: process.env.REACT_APP_TP_ID,
    },
    validationSchema,
    onSubmit: async (values: any, action) => {
      console.log('submited', values);
      setIsLoading(true);
      if (AddRole) {
        delete values.selectedScopeId;
        await dispatch(createRole(values));
        await getRolesAndScopes();
        // toast.custom((t) => <Toast id={t.id} title="SUCCESS" content={Constants.ROLE_CREATED} />);
        helperFunctions.showToast(`${Constants.ROLE_CREATED}`, 'success');
      } else {
        console.log('update');
        delete values.tpId;
        await dispatch(updateRole(values));
        await getRolesAndScopes();
        setIsLoading(false);
        // toast.custom((t) => <Toast id={t.id} title="SUCCESS" content={Constants.ROLE_UPDATED} />);
        helperFunctions.showToast(`${Constants.ROLE_UPDATED}`, 'success');
      }
      action.resetForm();
      handleCloseModule();
    },
  });

  const handleFieldForAddAndEdit = (
    action: string,
    index: number | undefined,
    isForDelete = false
  ) => {
    if (action === 'add') {
      formik.setFieldValue('selectedScopeId', '');
      formik.setFieldValue('name', '');
      formik.setFieldValue('previous_scope', []);
      formik.setFieldValue('new_scope', []);
      setAddRole(true);
      setSendScopeModelOpen(true);
    } else if (action === 'edit' && index != undefined) {
      console.log(role[index]);
      formik.setFieldValue('selectedScopeId', role[index]._id);
      formik.setFieldValue('name', role[index].name);
      formik.setFieldValue('previous_scope', [...role[index].new_scope]);
      formik.setFieldValue('new_scope', [...role[index].new_scope]);
      if (!isForDelete) setSendScopeModelOpen(true);
    }
  };

  const handleCloseModule = () => {
    setSendScopeModelOpen(false);
    setAddRole(false);
    setTabValue(0);
    // setRoleTabValue(0);
  };

  const deleteScope = (index: number, scope: string) => {
    if (role[index].new_scope.length > 1) {
      role[index].new_scope = [
        ...role[index].new_scope.filter((ele: string) => ele != scope),
      ];
      handleFieldForAddAndEdit('edit', index, true);
      setIsDeleting(true);
    }
  };

  const getRolesAndScopes = async () => {
    setIsLoading(true);
    dispatch(getRoleAndScope(''))
      .unwrap()
      .then((data: any) => {
        console.log(
          '🚀 ~ file: ResourceForm.tsx:211 ~ .then ~ data:',
          data.data[0].name
        );
        setRole(data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        // setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        // setloader(false);
      });
  };

  // for delete scope
  useEffect(() => {
    if (isDeleting) {
      console.log('hit');
      formik.handleSubmit();
      setIsDeleting(false);
    }
  }, [
    isDeleting,
    formik.values.new_scope,
    formik.values.selectedScopeId,
    formik.values.name,
    formik.values.previous_scope,
  ]);

  useEffect(() => {
    getRolesAndScopes();
  }, []);

  useEffect(() => {
    console.log('role', role);
  }, [role]);

  useEffect(() => {
    setScopeAndModuleList(scopeAndModelsList);
  }, []);

  const sendScopeModal = {
    isOpen: isSendScopeModelOpen,
    title: '',
    content: (
      <>
        <div className='scope-popup'>
          <div className='scope-block m-b-45'>
            <div className='flex-between'>
              <div className='input-area'>
                {!AddRole ? (
                  <h6>Add scopes</h6>
                ) : (
                  <div>
                    <h5 className='popup-title'>Add role</h5>
                    <div className='input-group'>
                      <label htmlFor='role_name'>Role name</label>
                      <input
                        id='name'
                        className='form-control'
                        type='text'
                        placeholder='Enter your role name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <span className='error-message'>
                          {formik.errors.name as string}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div>
                <p>{formik.values.new_scope.length} added</p>
              </div>
            </div>
            {!AddRole && (
              <p>
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content. Lorem ipsum
                may be used as a placeholder before final copy is available
              </p>
            )}
          </div>
          <div className='react-tabs-popup'>
            <div className='tabs-area popup'>
              <CustomTabs
                tablist={Object.keys(scopeAndModelsList)}
                onChangeTab={(e: any, index) => {
                  console.log('test', e);
                  setTabValue(index);
                }}
                addclass='role-tabs'
              >
                {Object.keys(scopeAndModelsList).map(
                  (key: string, index: number) => (
                    <TabPanel value={tabValue} tabIndex={index}>
                      {handleTabValueFromScopeList(tabValue, undefined).map(
                        (key: string, index: number) => (
                          <div className='mw-block  checkbox-container'>
                            <label
                              htmlFor={
                                handleTabValueFromScopeList(tabValue, key).scope
                              }
                              className='mw-check-container'
                            >
                              {handleTabValueFromScopeList(tabValue, key).name}
                              <span>
                                {
                                  handleTabValueFromScopeList(tabValue, key)
                                    .scope
                                }
                              </span>
                              <input
                                id={
                                  handleTabValueFromScopeList(tabValue, key)
                                    .scope
                                }
                                value={
                                  handleTabValueFromScopeList(tabValue, key)
                                    .scope
                                }
                                type='checkbox'
                                name={
                                  handleTabValueFromScopeList(tabValue, key)
                                    .name
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    // setSelectedScope(data=>[...data,e.target.value])
                                    formik.setFieldValue('new_scope', [
                                      ...formik.values.new_scope,
                                      e.target.value,
                                    ]);
                                  } else {
                                    // setSelectedScope(data=>[...data.filter((ele:string)=>ele!==e.target.value)])
                                    formik.setFieldValue('new_scope', [
                                      ...formik.values.new_scope.filter(
                                        (ele: string) => ele !== e.target.value
                                      ),
                                    ]);
                                  }
                                }}
                                checked={formik.values.new_scope.includes(
                                  handleTabValueFromScopeList(tabValue, key)
                                    .scope as never
                                )}
                              />
                              <span className='checkmark'></span>
                            </label>
                          </div>
                        )
                      )}
                    </TabPanel>
                  )
                )}
                {formik.touched.new_scope && formik.errors.new_scope && (
                  <span className='error-message'>
                    {typeof formik.errors.new_scope === 'string'
                      ? formik.errors.new_scope
                      : null}
                  </span>
                )}
              </CustomTabs>
              <div className='right-btn'>
                <button
                  type='submit'
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  className='btn secondary-btn scope-btn'
                >
                  {AddRole ? 'Save' : 'Update'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
    closeAction: handleCloseModule,
  };
  return (
    <div className='trust-management resources-roles'>
      <Loading status={isLoading} />
      <CustomModal {...sendScopeModal} />
      <div className='main-text'>
        <p>
          Manage what is displayed on admin, clinician and patient portals by
          enabling or disabling certain features. You can disable entire modules
          in one go or selected features based on each role.
        </p>
      </div>
      <div className='outer-container'>
        <div className='dash-tab-grey relative'>
          <div className='tabs-area'>
            <div className='resource-role-grid'>
              <div className='resources-button'>
                <button
                  type='button'
                  onClick={() => handleFieldForAddAndEdit('add', undefined)}
                >
                  Add role
                </button>
              </div>
              <div className=''></div>
            </div>
            <CustomTabs
              tablist={role.map((ele: any) => ele.name)}
              onChangeTab={(e: any, index) => {
                console.log('test', e, index);
                setRoleTabValue(index);

                // setAddRole(false);
                // setRole(e)
              }}
              addclass='role-tabs'
            >
              {!!role.length && (
                <>
                  <div className='right-btn'>
                    <button
                      type='submit'
                      onClick={() =>
                        handleFieldForAddAndEdit('edit', roleTabValue)
                      }
                      className='btn secondary-btn scope-btn'
                    >
                      + Add scopes
                    </button>
                  </div>
                </>
                // <button className="right-tab" onClick={() => handleFieldForAddAndEdit("edit", roleTabValue)}>
                //   + Add scopes
                // </button>
              )}
              {!AddRole ? (
                <>
                  {role.map((ele: any, index: number) => (
                    <TabPanel value={roleTabValue} tabIndex={index}>
                      {role[roleTabValue].new_scope.map((item: string) => (
                        <div className='scope-section'>
                          <div className='scope-block flex-center'>
                            <h6>{handleScopeAndModule(item).name}</h6>
                            <span>/{item}</span>
                          </div>
                          <div className='scope-description'>
                            <div>
                              <p>{handleScopeAndModule(item).description}</p>
                            </div>
                            <div>
                              <button
                                onClick={() => deleteScope(index, item)}
                                type='button'
                              >
                                delete
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </TabPanel>
                  ))}
                </>
              ) : (
                ''
              )}
            </CustomTabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roles;
