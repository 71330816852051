import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwt from 'jwt-decode';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  getResourceList,
  resourceListSelector,
  getResourceType,
  getResourceTopic,
  resourceTypesSelector,
  resourceTopicsSelector,
} from '../../features/Resource/ResourceSlice';
import { getAuthorList, authorSelector } from '../../features/User/UserSlice';

import sortDownIcon from '../../assets/images/arrow-down-short.svg';
import sortUpIcon from '../../assets/images/arrow-up-short.svg';

import './Resource.css';
import ResourceFilter from './ResourceFilter';
import Pagination from '../../components/Pagination';
import { Constants } from '../../global/Constants';
import { Search } from '../../components/Search';
import {
  getTagList,
  tagListSelector,
} from '../../features/TagList/TagListSlice';
import Loading from '../../components/loading/Loading';
import helperFunctions from '../../global/HelperService';
import { decodeEntities } from '../../utils/decodeEntities';
import Button from '../../components/Button/Button';
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ResourceListTable from '../../components/Table/ResourceListTable';

const ResourceList = () => {
  const tabIndex = 0;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [sort, setSort] = useState<{ sortField: string; sortOrder: number }>({
    sortField: 'createdAt',
    sortOrder: -1,
  });
  const [searchText, setSearchText] = useState<string>('');
  const timerId: React.MutableRefObject<number | undefined> = useRef<number>();
  const resources: any = useSelector(resourceListSelector);
  const [filters, setFilters] = useState({});
  const [pageNo, setPageNo] = React.useState(1);
  const resourceTypes: any = useAppSelector(resourceTypesSelector);
  const resourceTopics: any = useAppSelector(resourceTopicsSelector);
  const authorsList: any = useSelector(authorSelector);
  const tagListArray: any = useAppSelector(tagListSelector);
  const [tagList, setTagList] = useState<any>([]);
  const [loader, setloader] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    fetchResources();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort.sortOrder, sort.sortField]);

  useEffect(() => {
    fetchResourceTypes();
    fetchResourceTopics();
    //fetchAuthorsList();
    fetchTagLists();
  }, []);

  const fetchTagLists = (requestQuery?: any) => {
    setloader(true);
    dispatch(
      getTagList({
        searchString: '',
        page: 1,
        perPage: 12,
        ...requestQuery,
      })
    )
      .unwrap()
      .then((data: any) => {
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };

  useEffect(() => {
    if (tagListArray?.tagList?.tags) {
      setTagList(tagListArray?.tagList?.tags);
    }
  }, [tagListArray]);
  // useEffect(() => {
  //   if(userRoles && shortenRole && userRoles.includes(shortenRole) && shortenRole!=='SA')
  //     history.goBack();
  // }, []);

  const onResourceClick = (id: string) => {
    localStorage.setItem('fromPage', 'cur');
    navigate(`/resource/view/${id}`);
  };

  const handleSort = (sortField: string) => {
    setSort((prevState) => ({
      sortField,
      sortOrder: prevState.sortOrder === 1 ? -1 : 1,
    }));
  };

  const handleSearchChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(timerId.current);
      setSearchText(value);
      setPageNo(1);
      timerId.current = setTimeout(() => {
        fetchResources({ page: 1, searchString: value });
      }, 500) as any;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const toggleSearch = () => {
    if (showSearch) {
      fetchResources({ page: 1, searchString: '' });
    }
    setShowSearch(!showSearch);
    setSearchText('');
    setPageNo(1);
  };

  const handleFilterSubmit = (data: any) => {
    // eslint-disable-next-line array-callback-return
    Object?.keys(data)?.map((key) => {
      if (data[key] === undefined) {
        delete data[key];
      } else if (data[key] === false || data[key].length === 0) {
        delete data[key];
      }
    });
    console.log('filterData', data);
    setFilters(data);
    setShowFilter(false);
    setPageNo(1);
    fetchResources({ page: 1, filter: data });
  };

  const activeHandler = (clickedActive: string) => {
    setPageNo(parseInt(clickedActive));
    fetchResources({ page: clickedActive });
  };

  const fetchResources = (requestQuery?: any) => {
    setloader(true);
    dispatch(
      getResourceList({
        sortOrder: sort.sortOrder,
        sortBy: sort.sortField,
        searchString: searchText,
        filter: filters,
        page: pageNo,
        perPage: Constants.ROWS_PER_PAGE,
        //fromReviewDate: requestQuery.fromReviewDate,
        //toReviewDate: requestQuery.toReviewDate,//
        ...requestQuery,
      })
    )
      .unwrap()
      .then((data: any) => {
        window.scrollTo(0, 300);
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };
  const fetchResourceTypes = (resourceTypes?: any) => {
    setloader(true);
    dispatch(getResourceType('1'))
      .unwrap()
      .then((data: any) => {
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };
  const fetchResourceTopics = (resourceTopics?: any) => {
    setloader(true);
    dispatch(getResourceTopic('1'))
      .unwrap()
      .then((data: any) => {
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };
  const fetchAuthorsList = (authorsList?: any) => {
    setloader(true);
    dispatch(getAuthorList('1'))
      .unwrap()
      .then((data: any) => {
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };
  const isSharedResource = (resource) => {
    const jwtDecode: any = jwt(localStorage.getItem('token') || '');
    const shortenRoles = jwtDecode.roles || [];
    const userId = jwtDecode.user_id || '';
    return resource.trust
      ? resource.trust._id !== jwtDecode.trustId ||
          (resource.publishFor == 'specificService'
            ? resource.publishForInSpecific.includes(jwtDecode.trustId)
            : '')
      : false;
  };

  return (
    <div className='outer-container'>
      <Loading status={loader} />
      <div className={showFilter ? 'backdrop' : ''}>
        <div className='container-header'>
          <h3 className='text-bold text-baseColor'>Current resource list</h3>
        </div>
        <div className='inner-container'>
          <div className='container-xl'>
            <div className='grid grid-cols-1 gap-10'>
              <div className='middle-area'>
                <div className='search relative'>
                  <Search handleSearchChange={handleSearchChange} />
                </div>
                <div className='flex justify-end items-end '>
                  <Button
                    btntype={'button'}
                    addClass='btn-icon halo '
                    onClick={() => setShowFilter(true)}
                    text='Filter'
                    showIcon
                    iconPlaceholder={<FilterIcon />}
                  />
                </div>
              </div>
              <div className='list-container-table table-responsive'>
                <div className='horizontal-scrolll'>
                  <ResourceListTable
                    resources={resources}
                    sort={sort}
                    handleSort={handleSort}
                    onResourceClick={onResourceClick}
                    decodeEntities={decodeEntities}
                    isSharedResource={isSharedResource}
                    tabIndex={0}
                  />
                </div>

                {resources.total > 10 && (
                  <Pagination
                    active={pageNo}
                    size={
                      resources.total
                        ? Math.ceil(resources.total / Constants.ROWS_PER_PAGE)
                        : 1
                    }
                    step={2}
                    onClickHandler={activeHandler}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showFilter && (
        <ResourceFilter
          closeFilter={() => setShowFilter(false)}
          handleFilter={handleFilterSubmit}
          defaultValues={filters}
          resourceTypes={resourceTypes}
          resourceTopics={resourceTopics}
          authorsList={authorsList}
          tagList={tagList}
        />
      )}
    </div>
  );
};

export default ResourceList;
