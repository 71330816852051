import React from 'react';
import { CommonHeader } from './Header/CommonHeader';
import Navbar from './Navbar';
import { FooterBentoMenu } from './BentoMenu/BentoMenu';
import { Outlet } from 'react-router';
import useAuthContext from '../context/provider/AuthContext';
import usePermissionContext from '../context/provider/PermissionContext';
import { MainPortalRolesType } from '../context/type/UserRouterRoles';

interface MainLayoutProps {}

export const MainLayout: React.FC<MainLayoutProps> = ({}) => {
  const {
    state: { user, isAuthenticated, mainScopes },
  } = useAuthContext();

  // console.log('scopes', user, isAuthenticated, mainScopes);

  return (
    <div>
      {isAuthenticated && mainScopes.length > 0 && (
        <>
          <CommonHeader headerRef={null} />
          <Navbar />
          <Outlet />
          <FooterBentoMenu />
        </>
      )}
    </div>
  );
};
