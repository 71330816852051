import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import jwt from 'jwt-decode';
import moment from 'moment';
import { scopeProperty } from '../../global/scopePayload';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import CustomModal from '../../components/CustomModal';
import { ReactPlayer } from '../../components/ReactPlayer';
import { FormCheckBox } from '../../components/FormCheckBox';
import { FormDatePicker } from '../../components/FormDatePicker';
import { FormInput } from '../../components/FormInput';
import PDFViewer from '../../components/PDFViewer';
import Toast from '../../components/Toast';
import back from '../../assets/images/back.svg';

import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

import {
  getResource,
  resourceSelector,
  saveReviewReason,
  resourceApprove,
  cancelApproval,
  publishContent,
  unpublishConent,
  addCurrentResources,
  removeCurrentResources,
} from '../../features/Resource/ResourceSlice';
import ArrowLeft from '../../assets/images/arrow_left.svg';
import './Resource.css';
import { Constants } from './../../global/Constants';
import Loading from '../../components/loading/Loading';
import helperFunctions from '../../global/HelperService';
import { decodeEntities } from '../../utils/decodeEntities';
import { Back } from '../../components/back/Back';

const ResourceView = () => {
  const tabIndex = 0;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id }: any = useParams();
  const { resource } = useAppSelector(resourceSelector);
  const [reasonForReview, setReasonForReview] = useState<string[]>([]);
  const [reasonView, setReasonView] = useState(false);
  const [cancelApprovalModalView, setCancelApprovalModal] = useState(false);
  const [publishContentModalView, setPublishContentModal] = useState(false);
  const [unpublishContentModalView, setUnPublishContentModal] = useState(false);
  const [loader, setloader] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: 'all' });

  useEffect(() => {
    window.scrollTo(0, 0);
    setloader(true);
    dispatch(getResource(id))
      .unwrap()
      .then((data: any) => {
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  }, []);

  const onEditClick = () => {
    navigate(`/resource/edit/${id}`);
  };

  const goToTableView = () => {
    let fromPage = localStorage.getItem('fromPage');
    console.log(
      '🚀 ~ file: ResourceView.tsx ~ line 61 ~ goToTableView ~ fromPage',
      fromPage
    );
    if (fromPage && fromPage === 'lib') {
      navigate(`/resource-library`);
    } else {
      navigate(`/resource`);
    }
    //console.log(resource)
    // navigate(`/resource`);
  };

  const getFileType = () => {
    if (resource.filePath) {
      return resource.filePath.split('.').pop();
    }
  };

  const onCheckboxChange = (value: string) => {
    console.log('value---', value);
    const reason = [...reasonForReview];
    if (!reason.includes(value)) {
      reason.push(value);
    } else {
      const index = reason.indexOf(value);
      reason.splice(index, 1);
    }
    setReasonForReview(reason);
  };

  const onReasonViewClose = () => {
    setReasonForReview([]);
    setReasonView(false);
    reset();
  };

  const onReasonSubmit = (postData: any) => {
    console.log('postData---', postData);
    const commentMessage =
      postData && postData.comment ? postData.comment.trim() : '';
    console.log(
      'commentMessage resView :>>',
      commentMessage,
      commentMessage?.length
    );
    if (commentMessage && commentMessage.length >= 3) {
      setloader(true);
      dispatch(saveReviewReason({ postData, id }))
        .unwrap()
        .then((data: any) => {
          onReasonViewClose();
          dispatch(getResource(id));
          // toast.custom((t) => (
          //   <Toast
          //     id={t.id}
          //     title="SUCCESS"
          //     content={Constants.REVIEW_ADD_SUCCES}
          //   />
          // ));
          helperFunctions.showToast(
            `${Constants.REVIEW_ADD_SUCCES}`,
            'success'
          );
          setloader(false);
        })
        .catch((e) => {
          setloader(false);
          console.log('error---', e);
        });
    }
  };

  const cancelApprovalModal = {
    isOpen: cancelApprovalModalView,
    title: 'Resource approval removed',
    content: `Removed approval for ${resource.title} is removed. The author will be notified of the change.`,
    primaryButton: (
      <button className='btn secondary-btn' onClick={() => goToTableView()}>
        Okay
      </button>
    ),
    closeAction: setCancelApprovalModal,
  };

  const publishContentModal = {
    isOpen: publishContentModalView,
    title: 'Published',
    content: 'Your content has been published.',
    primaryButton: (
      <button className='btn secondary-btn' onClick={() => goToTableView()}>
        Ok
      </button>
    ),
    closeAction: setPublishContentModal,
  };

  const unpublishContentModal = {
    isOpen: unpublishContentModalView,
    title: 'Unpublished',
    content: 'Content has been unpublished',
    primaryButton: (
      <button className='btn secondary-btn' onClick={() => goToTableView()}>
        Ok
      </button>
    ),
    closeAction: setUnPublishContentModal,
  };

  const getEditAccess = () => {
    const jwtDecode: any = jwt(localStorage.getItem('token') || '');
    const shortenRoles = jwtDecode.scopePermissions || [];
    const userId = jwtDecode.user_id || '';
    return (
      !resource.isPublished &&
      !resource.isApproved &&
      !resource.isSubmitted &&
      shortenRoles.includes(scopeProperty.RESOURCE_CREATE) &&
      userId == resource?.owner?._id
    );
  };

  const getReviewAccess = () => {
    const jwtDecode: any = jwt(localStorage.getItem('token') || '');
    const shortenRoles = jwtDecode.scopePermissions || [];
    const userId = jwtDecode.user_id || '';
    return (
      userId !== resource.owner._id &&
      !resource.isPublished &&
      !resource.isApproved &&
      resource.isSubmitted &&
      (shortenRoles.includes(scopeProperty.RESOURCE_APPROVE) ||
        shortenRoles.includes(scopeProperty.RESOURCE_PUBLISH))
    );
  };

  const getApproveAccess = () => {
    const jwtDecode: any = jwt(localStorage.getItem('token') || '');
    const shortenRoles = jwtDecode.scopePermissions || [];
    const userId = jwtDecode.user_id || '';
    return (
      userId !== resource.owner._id &&
      !resource.isPublished &&
      !resource.isApproved &&
      resource.isSubmitted &&
      shortenRoles.includes(scopeProperty.RESOURCE_APPROVE)
    );
  };

  const getCancelApprovalAccess = () => {
    const jwtDecode: any = jwt(localStorage.getItem('token') || '');
    const shortenRoles = jwtDecode.scopePermissions || [];
    const userId = jwtDecode.user_id || '';
    return (
      !resource.isPublished &&
      resource.isApproved &&
      resource.isSubmitted &&
      shortenRoles.includes(scopeProperty.RESOURCE_APPROVE)
    );
  };

  const getPublishAccess = () => {
    const jwtDecode: any = jwt(localStorage.getItem('token') || '');
    const shortenRoles = jwtDecode.scopePermissions || [];
    // console.log("🚀 ~ file: ResourceView.tsx:229 ~ getPublishAccess ~ shortenRoles:", shortenRoles)
    const userId = jwtDecode.user_id || '';
    return (
      !resource.isPublished &&
      // resource.isApproved &&
      resource.isSubmitted &&
      shortenRoles.includes(scopeProperty.RESOURCE_PUBLISH)
    );
  };

  const getUnPublishAccess = () => {
    const jwtDecode: any = jwt(localStorage.getItem('token') || '');
    const shortenRoles = jwtDecode.scopePermissions || [];
    const userId = jwtDecode.user_id || '';
    return (
      resource.isPublished &&
      // resource.isApproved &&
      resource.isSubmitted &&
      shortenRoles.includes(scopeProperty.RESOURCE_PUBLISH)
    );
  };

  const getAddResourceAccess = () => {
    const jwtDecode: any = jwt(localStorage.getItem('token') || '');
    const shortenRoles = jwtDecode.roles || [];
    const userId = jwtDecode.user_id || '';
    const ownTrustId = jwtDecode.trustId || '';
    // return true
    return (
      !resource.sharedTrust.includes(jwtDecode.trustId) &&
      shortenRoles.includes('TA')
    );
  };

  const removeResourceAccess = () => {
    const jwtDecode: any = jwt(localStorage.getItem('token') || '');
    if (jwtDecode) {
      const shortenRoles = jwtDecode.roles || [];
      const userId = jwtDecode.user_id || '';
      const ownTrustId = jwtDecode.trustId || '';
      return (
        resource.sharedTrust.includes(jwtDecode.trustId) &&
        shortenRoles.includes('TA')
      );
    }
  };

  const onContentApprove = () => {
    setloader(true);
    dispatch(resourceApprove({ id }))
      .unwrap()
      .then((data: any) => {
        // toast.custom((t) => (
        //   <Toast
        //     id={t.id}
        //     title="SUCCESS"
        //     content={Constants.CONTENT_APPROVED}
        //   />
        // ));
        helperFunctions.showToast(`${Constants.CONTENT_APPROVED}`, 'success');

        goToTableView();
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };

  const cancelApprovalAction = () => {
    setloader(true);
    dispatch(cancelApproval({ id }))
      .unwrap()
      .then((data: any) => {
        dispatch(getResource(id));
        setCancelApprovalModal(true);
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };

  const publishResourceAction = () => {
    setloader(true);
    dispatch(publishContent({ id }))
      .unwrap()
      .then((data: any) => {
        dispatch(getResource(id));
        setPublishContentModal(true);
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        if (e?.message) helperFunctions.showToast(e?.message, 'error');
        setloader(false);
      });
  };

  const unpublishResourceAction = () => {
    setloader(true);
    dispatch(unpublishConent({ id }))
      .unwrap()
      .then((data: any) => {
        dispatch(getResource(id));
        setUnPublishContentModal(true);
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };

  const addToCurrentResources = () => {
    setloader(true);
    dispatch(addCurrentResources({ id }))
      .unwrap()
      .then((data: any) => {
        // toast.custom((t) => (
        //   <Toast id={t.id} title="SUCCESS" content={data.message} />
        // ));
        helperFunctions.showToast(`${data.message}`, 'success');

        dispatch(getResource(id));
        //setUnPublishContentModal(true);
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };
  const removeFromCurrentResources = () => {
    setloader(true);
    dispatch(removeCurrentResources({ id }))
      .unwrap()
      .then((data: any) => {
        // toast.custom((t) => (
        //   <Toast id={t.id} title="SUCCESS" content={data.message} />
        // ));
        helperFunctions.showToast(`${data.message}`, 'success');

        dispatch(getResource(id));
        setloader(false);
        //setUnPublishContentModal(true);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };
  return (
    <div className='outer-container'>
      <div className='view-resource'>
        <Loading status={loader} />
        <CustomModal {...cancelApprovalModal} />
        <CustomModal {...publishContentModal} />
        <CustomModal {...unpublishContentModal} />
        <div className='container-header'>
          <div className='flex items-center gap-3'>
            <Back btntype='button' onClick={() => goToTableView()} />
            <h3 className='text-bold text-baseColor'>Current resource list</h3>
          </div>
          <div className='flex items-end justify-end'>
            {getEditAccess() && (
              <>
                <span
                  tabIndex={tabIndex}
                  className='btn primary-btn'
                  onClick={() => onEditClick()}
                >
                  Edit resource
                </span>
              </>
            )}
            {/* {getCancelApprovalAccess() && (
                <span
                  tabIndex={tabIndex}
                  className="btn primary-btn"
                  onClick={() => cancelApprovalAction()}
                >
                  Cancel approval
                </span>
              )}
              {getPublishAccess() && (
                <span
                  tabIndex={tabIndex}
                  className="primary-btn btn onkeuyp"
                  onClick={() => publishResourceAction()}
                >
                  Publish
                </span>
              )}
              {getUnPublishAccess() && (
                <span
                  tabIndex={tabIndex}
                  className="btn primary-btn"
                  onClick={() => unpublishResourceAction()}
                >
                  Unpublish
                </span>
              )}
              {getAddResourceAccess() && (
                <span
                  tabIndex={tabIndex}
                  className="btn primary-btn"
                  onClick={() => addToCurrentResources()}
                >
                  Add resource to my trust
                </span>
              )}
              {removeResourceAccess() && (
                <span
                  className="btn primary-btn"
                  onClick={() => removeFromCurrentResources()}
                >
                  Remove resource from my trust
                </span>
              )} */}
          </div>
        </div>
        <div className='inner-container'>
          <div className='container-xl'>
            <div className='view-container'>
              {!resource.isApproved &&
                !resource.isSubmitted &&
                !resource.isPublished &&
                resource.errorInfo && (
                  <div className='updated-info note-info'>
                    <div className='content-info'>
                      <div className='title mb-3' tabIndex={tabIndex}>
                        Error Reported
                      </div>
                      <div className='mb-2' tabIndex={tabIndex}>
                        By: {resource.errorInfo.by.name}
                      </div>
                      <div className='mb-2' tabIndex={tabIndex}>
                        On:{' '}
                        {moment(new Date(resource.errorInfo.at))
                          .locale('en-US')
                          .format('DD/MM/YYYY')}
                      </div>
                      <div className='mb-2' tabIndex={tabIndex}>
                        Author: {resource?.owner?.name}
                      </div>
                      <div className='mb-2' tabIndex={tabIndex}>
                        Created on:{' '}
                        {moment(new Date(resource.createdAt))
                          .locale('en-US')
                          .format('DD/MM/YYYY')}
                      </div>
                    </div>
                    <div className='content-info'>
                      <div className='title mb-3' tabIndex={tabIndex}>
                        Note
                      </div>
                      <span>{resource.errorInfo.message}</span>
                    </div>
                  </div>
                )}

              <div className=' mb-4 d-sm-flex justify-between'>
                <div className='title' tabIndex={tabIndex}>
                  {decodeEntities(resource.title)}
                </div>

                {process.env.REACT_APP_REVIEW_DATE != 'false' &&
                resource.reviewDate &&
                !resource.approvedInfo ? (
                  <div className='fw-bold mt-2 mt-sm-0' tabIndex={tabIndex}>
                    To be approved by:{' '}
                    {moment(new Date(resource.reviewDate)).format('DD/MM/YYYY')}
                  </div>
                ) : (
                  ''
                )}

                {resource.approvedInfo ? (
                  <div className='fw-bold mt-2 mt-sm-0' tabIndex={tabIndex}>
                    Approved on:{' '}
                    {moment(new Date(resource.approvedInfo.at)).format(
                      'DD/MM/YYYY'
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {resource.type !== 'Events & Groups' ? (
                <>
                  <div
                    className='mb-4'
                    dangerouslySetInnerHTML={{ __html: resource.resourceBody }}
                  ></div>
                  {(resource.filePath || resource.videoUrl) && (
                    <>
                      <div className='file mb-4'>
                        {getFileType() === 'pdf' && (
                          <div
                            className='pdf-viewer'
                            tabIndex={tabIndex}
                            aria-label='PDE Viewer'
                          >
                            <PDFViewer renderData={resource.filePath} />
                          </div>
                        )}
                        {resource.type === 'Videos' && (
                          // <video
                          //   controls
                          //   className='video-viewer'
                          //   tabIndex={tabIndex}
                          // >
                          //   <source src={resource.videoUrl || resource.filePath} type="video/youtube"/>
                          //   Your browser does not support HTML video.
                          // </video>
                          <ReactPlayer
                            width='100%'
                            height='400px'
                            url={resource.videoUrl || resource.filePath}
                          />
                        )}
                        {getFileType() !== 'pdf' &&
                          getFileType() !== 'mp4' &&
                          resource.type !== 'Videos' && (
                            <img
                              tabIndex={tabIndex}
                              className='image-viewer'
                              src={resource.filePath}
                              alt='file'
                            />
                          )}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {resource.eventBody?.title ? (
                    <div className='fw-bold' tabIndex={tabIndex}>
                      Title: {resource.eventBody?.title}
                    </div>
                  ) : (
                    ''
                  )}

                  {resource.eventBody?.information ? (
                    <div className='fw-bold' tabIndex={tabIndex}>
                      Information: {resource.eventBody?.information}
                    </div>
                  ) : (
                    ''
                  )}
                  {resource.eventBody?.fromDate ? (
                    <div className='fw-bold' tabIndex={tabIndex}>
                      From date:{' '}
                      {moment(new Date(resource.eventBody?.fromDate)).format(
                        'DD/MM/YYYY'
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  {resource.eventBody?.toDate ? (
                    <div className='fw-bold' tabIndex={tabIndex}>
                      To date:{' '}
                      {moment(new Date(resource.eventBody?.toDate)).format(
                        'DD/MM/YYYY'
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  {resource.eventBody?.startsOn ? (
                    <div className='fw-bold' tabIndex={tabIndex}>
                      Starts on:{' '}
                      {moment(new Date(resource.eventBody?.startsOn)).format(
                        'DD/MM/YYYY hh:mm a'
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  {resource.eventBody?.endsOn ? (
                    <div className='fw-bold' tabIndex={tabIndex}>
                      Ends on:{' '}
                      {moment(new Date(resource.eventBody?.endsOn)).format(
                        'DD/MM/YYYY hh:mm a'
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  <div>
                    {resource.eventBody?.link && (
                      <p tabIndex={tabIndex}>
                        Link: {resource.eventBody?.link}
                      </p>
                    )}
                  </div>
                  <div>
                    {resource.eventBody?.location && (
                      <p tabIndex={tabIndex}>
                        Location: {resource.eventBody?.location}
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className='app-sub-header multi-btn relative'>
              {/* {getApproveAccess() && (
                <span
                  tabIndex={tabIndex}
                  className="btn halo"
                  onClick={() => {
                    onContentApprove();
                  }}
                >
                  Approve
                </span>
              )} */}

              {getPublishAccess() && (
                <span
                  tabIndex={tabIndex}
                  className='primary-btn btn onkeuyp'
                  onClick={() => publishResourceAction()}
                >
                  Publish
                </span>
              )}
              {getUnPublishAccess() && (
                <span
                  tabIndex={tabIndex}
                  className='btn primary-btn'
                  onClick={() => unpublishResourceAction()}
                >
                  Unpublish
                </span>
              )}

              {getReviewAccess() && (
                <>
                  <span
                    tabIndex={tabIndex}
                    className='btn primary-btn'
                    onClick={() => {
                      reasonView ? onReasonViewClose() : setReasonView(true);
                    }}
                  >
                    Flag for review
                  </span>
                </>
              )}
              {reasonView && (
                <div className='review-container mt-3'>
                  <form
                    className='review-form'
                    onSubmit={handleSubmit(onReasonSubmit)}
                  >
                    <div className='flex justify-between'>
                      <span className='mb-3 fw-bold' tabIndex={tabIndex}>
                        Reason for review
                      </span>
                      <span
                        tabIndex={tabIndex}
                        className='cursor-pointer onkeyup'
                        onClick={() => {
                          reasonView
                            ? onReasonViewClose()
                            : setReasonView(true);
                        }}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                    <div className='mb-3'>
                      <div className='mb-2' tabIndex={tabIndex}>
                        Comments
                      </div>
                    </div>
                    {/* <div className="mb-2" tabIndex={tabIndex}>
                Add note
              </div> */}
                    <FormInput
                      id='comment'
                      type='textarea'
                      name='comment'
                      label=''
                      placeholder=''
                      register={register}
                      rules={{
                        required: 'Comment is required',
                        minLength: {
                          value: 3,
                          message: 'Length must be 3 characters long',
                        },
                      }}
                      errors={errors}
                    />
                    {/* <div className="mb-3">
                <FormCheckBox
                  type="checkbox"
                  id="dueDateCheck"
                  label="Review due date"
                  name="dueDateCheck"
                  value={true}
                  register={register}
                  errors={errors}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onCheckboxChange(e.target.name);
                  }}
                />
              </div>
              {reasonForReview.includes("dueDateCheck") && (
                <FormDatePicker
                  id="reviewDate"
                  name="reviewDate"
                  label=""
                  rules={{}}
                  register={register}
                  errors={errors}
                  control={control}
                />
              )} */}
                    <div className='app-sub-header multi-btn'>
                      <button className='btn primary-btn' type='submit'>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceView;
