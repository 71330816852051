import React from "react";
import "./styles/FormInput.css";
import { ErrorMessage } from "@hookform/error-message";

import activeCheck from "../assets/images/login-check-activated.svg";
import disableCheck from "../assets/images/login-check-disabled.svg";

import { FormErrorMessage } from "./FormErrorMessage";
import { getRandomText } from "../global/HelperFunctions";

export const FormInput = ({
  id = getRandomText(10),
  name,
  type,
  register,
  rules,
  errors,
  linkText,
  isLogin,
  iscommon,
  icon,
  eyeIconBefore,
  eyeIconAfter,
  iconOnClick,
  eyeIconClick,
  passwordErrorFlag,
  passwordErrorFlagC,
  changepassword,
  Onclick,
  frmProfilePasswordPage,
  ...props
}: any): JSX.Element => {
  const tabIndex = 0;
  const notabIndex = -1;
  const hasError = !!(errors && errors[name]);
  return (
    <div
      className={`input-group textarea-wrap add-tag-input ${
        isLogin ? "form-login" : ""
      } ${iscommon && !frmProfilePasswordPage ? "form-common" : ""} ${
        iscommon && frmProfilePasswordPage ? "form-login" : ""
      }
      `}
    >
      {props.label && <label htmlFor={id}>{props.label}</label>}
      {eyeIconBefore && eyeIconAfter && (
        <img
          tabIndex={tabIndex}
          className="cnm-logo onkeyup toggle-pass-icon"
          style={{ float: "right", height: "30px" }}
          src={!eyeIconClick ? eyeIconBefore : eyeIconAfter}
          alt="cmn logo"
          onClick={iconOnClick}
        />
      )}
      {type === "textarea" ? (
        <textarea
          id={id}
          className="form-control has-success has-feedback"
          {...(register && register(name, rules))}
          {...props}
        />
      ) : (
        <>
          <input
            id={id}
            className="form-control has-success has-feedback"
            {...(register && register(name, rules))}
            {...props}
            type={
              (name === "newpassword" ||
                name === "password" ||
                name === "confirmPassword" ||
                name === "connewpassword") &&
              eyeIconClick
                ? "text"
                : type
            }
          />
          {changepassword && (
            <div className="psw-link-block">
              <span tabIndex={tabIndex} className="onkeyup" onClick={Onclick}>
                Change Password
              </span>
            </div>
          )}
        </>
      )}
      {isLogin && (
        <img
          tabIndex={notabIndex}
          src={hasError ? disableCheck : activeCheck}
          alt="check"
          className="input-icon"
        />
      )}
      {/* {isLogin && (
        <img
          src={hasError || (name === 'password' && passwordErrorFlag) || (name === 'confirmPassword' && passwordErrorFlagC) ? disableCheck : activeCheck}
          alt="check"
          className="input-icon"
        />
      )} */}
      {/* need to implement error handling */}
      {!isLogin && (
        <ErrorMessage
          errors={errors}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          name={name as any}
          render={({ message }) => (
            <FormErrorMessage>{message}</FormErrorMessage>
          )}
        />
      )}
      {linkText && (
        <div tabIndex={tabIndex} className="input-link">
          {linkText}
        </div>
      )}
    </div>
  );
};
