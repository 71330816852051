import React, { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { Profile } from '../pages/Account/Profile';
import { ResourcesApi } from '../pages/ResourcesApi/ResourcesApi';
import Loader from '../components/loading/Loading';
import Level1User from '../pages/Level1User/Level1User';
import Roles from '../pages/Roles/Roles';
import { scopeProperty } from '../global/scopePayload';
import ResourceRoute from '../pages/Resource/ResourceRoute';
import useAuthContext from '../context/provider/AuthContext';
import { MainLayout } from '../components/MainLayout';

const Login = React.lazy(() => import('../pages/Login/Login'));
const ForgotPassword = React.lazy(
  () => import('../pages/ForgotPassword/ForgotPassword')
);
const ResetPassword = React.lazy(
  () => import('../pages/ForgotPassword/ResetPassword')
);
const Resource = React.lazy(() => import('../pages/Resource/ResourceRoute'));

const UserManagement = React.lazy(
  () => import('../pages/UserManagement/UserManagement')
);
const Trust = React.lazy(() => import('../pages/Trust/TrustList'));
const Notification = React.lazy(
  () => import('../pages/Notification/Notification')
);
const NotificationView = React.lazy(
  () => import('../pages/Notification/NotificationView')
);
const NoAccess = React.lazy(() => import('../pages/NoAccess/NoAccess'));
const ResourceLibrary = React.lazy(
  () => import('../pages/ResourceLibrary/ResourceLibrary')
);
const ResourceType = React.lazy(
  () => import('../pages/ResourceTopic/ResourceTopic')
);
const ResourceTopic = React.lazy(
  () => import('../pages/ResourceTopic/ResourceTopic')
);
const ResourceTopicView = React.lazy(
  () => import('../pages/ResourceTopic/ResourceTopicView')
);
const ResourceTypeView = React.lazy(
  () => import('../pages/ResourceType/ResourceTypeView')
);

function CheckValidLogin() {
  const {
    state: { user, isAuthenticated, scopes },
    loginCheck,
  } = useAuthContext();

  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  // Handle login check based on URL search params
  useEffect(() => {
    const token = new URLSearchParams(search).get('token');
    if (token) {
      loginCheck(token);
    }
    // Other checks can be added here

    let indexUrl = '/resource';
    if (isAuthenticated) {
      if (scopes.includes('all')) {
        indexUrl = '/notification-centre';
      } else if (scopes.includes('L1')) {
        indexUrl = '/trust';
      } else if (scopes.includes('user:create')) {
        indexUrl = '/user-management';
      } else if (
        scopes.includes('resource:create') ||
        scopes.includes('resource:approve') ||
        scopes.includes('resource:publish')
      ) {
        indexUrl = '/resource';
      }
    }

    navigate(indexUrl, { replace: true });
  }, [pathname, scopes, navigate]);

  console.log('scopes', user, isAuthenticated, scopes);

  return <>Loading...</>;
}

export const AppRoutes = () => {
  const {
    state: { user, isAuthenticated },
  } = useAuthContext();
  const accessToken = localStorage.getItem('token');

  const navigate = useNavigate();

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
      // if (!accessToken) {
      //   navigate('/login', { replace: true });
      // }
    }, 1000);
  }, []);

  return (
    <Suspense fallback={<Loader status={showElement} />}>
      <Routes>
        <Route path='/validate-Login' element={<CheckValidLogin />} />
        <Route
          path='/'
          element={
            process.env.REACT_APP_LOGIN_TYPE === 'SSO' ? <Login /> : <Login />
          }
        />
        <Route
          path='/login'
          element={
            process.env.REACT_APP_LOGIN_TYPE === 'SSO' ? (
              <NoAccess />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/forgot-password'
          element={
            process.env.REACT_APP_LOGIN_TYPE === 'SSO' ? (
              <NoAccess />
            ) : (
              <ForgotPassword />
            )
          }
        />
        <Route
          path='/reset-password'
          element={
            process.env.REACT_APP_LOGIN_TYPE === 'SSO' ? (
              <NoAccess />
            ) : (
              <ResetPassword />
            )
          }
        />
        <Route
          path='/set-password'
          element={
            process.env.REACT_APP_LOGIN_TYPE === 'SSO' ? (
              <NoAccess />
            ) : (
              <ResetPassword />
            )
          }
        />
        <Route path='/endpoint' element={<ResourcesApi />} />
        {isAuthenticated && (
          <Route path='/' element={<MainLayout />}>
            <Route
              path='/profile'
              element={
                <PrivateRoute
                  accessRoles={[
                    scopeProperty.RESOURCE_VIEW,
                    scopeProperty.RESOURCE_CREATE,
                    scopeProperty.RESOURCE_APPROVE,
                    scopeProperty.RESOURCE_PUBLISH,
                    scopeProperty.USER_CREATE,
                    scopeProperty.ALL_ACCESS,
                    scopeProperty.RESOURCE_PUBLISHED_VIEW,
                  ]}
                >
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path='/resource/*'
              element={
                <PrivateRoute
                  accessRoles={[
                    scopeProperty.RESOURCE_VIEW,
                    scopeProperty.RESOURCE_CREATE,
                    scopeProperty.RESOURCE_APPROVE,
                    scopeProperty.RESOURCE_PUBLISH,
                    scopeProperty.ALL_ACCESS,
                    scopeProperty.RESOURCE_PUBLISHED_VIEW,
                  ]}
                >
                  <ResourceRoute />
                </PrivateRoute>
              }
            />
            <Route
              path='/user-management'
              element={
                <PrivateRoute accessRoles={[scopeProperty.USER_CREATE]}>
                  <UserManagement />
                </PrivateRoute>
              }
            />
            <Route
              path='/trust'
              element={
                <PrivateRoute accessRoles={[scopeProperty.ALL_TRUST_ACCESS]}>
                  <Trust />
                </PrivateRoute>
              }
            />
            <Route
              path='/systemAdmin'
              element={
                <PrivateRoute accessRoles={[scopeProperty.ALL_ACCESS]}>
                  <Level1User />
                </PrivateRoute>
              }
            />
            <Route
              path='/roles'
              element={
                <PrivateRoute accessRoles={[scopeProperty.ALL_ACCESS]}>
                  <Roles />
                </PrivateRoute>
              }
            />
            <Route
              path='/notification-centre'
              element={
                <PrivateRoute
                  accessRoles={[
                    scopeProperty.RESOURCE_CREATE,
                    scopeProperty.RESOURCE_APPROVE,
                    scopeProperty.RESOURCE_PUBLISH,
                    scopeProperty.USER_CREATE,
                    scopeProperty.ALL_ACCESS,
                    scopeProperty.RESOURCE_VIEW,
                    scopeProperty.RESOURCE_PUBLISHED_VIEW,
                  ]}
                >
                  <Notification />
                </PrivateRoute>
              }
            />
            <Route
              path='/notification/view/:id'
              element={
                <PrivateRoute
                  accessRoles={[
                    scopeProperty.RESOURCE_CREATE,
                    scopeProperty.RESOURCE_APPROVE,
                    scopeProperty.RESOURCE_PUBLISH,
                    scopeProperty.USER_CREATE,
                    scopeProperty.ALL_ACCESS,
                    scopeProperty.RESOURCE_VIEW,
                    scopeProperty.RESOURCE_PUBLISHED_VIEW,
                  ]}
                >
                  <NotificationView />
                </PrivateRoute>
              }
            />
            <Route
              path='/resource-library'
              element={
                <PrivateRoute
                  accessRoles={[
                    scopeProperty.RESOURCE_CREATE,
                    scopeProperty.RESOURCE_PUBLISH,
                    scopeProperty.ALL_ACCESS,
                    scopeProperty.RESOURCE_VIEW,
                    scopeProperty.RESOURCE_PUBLISHED_VIEW,
                    scopeProperty.RESOURCE_APPROVE,
                  ]}
                >
                  <ResourceLibrary />
                </PrivateRoute>
              }
            />
            <Route
              path='/resource-type'
              element={
                <PrivateRoute accessRoles={[scopeProperty.RESOURCE_CREATE]}>
                  <ResourceType />
                </PrivateRoute>
              }
            />
            <Route
              path='/resource-topic'
              element={
                <PrivateRoute accessRoles={[scopeProperty.RESOURCE_CREATE]}>
                  <ResourceTopic />
                </PrivateRoute>
              }
            />
            <Route
              path='/resource-topics/view/:id'
              element={
                <PrivateRoute
                  accessRoles={[
                    scopeProperty.RESOURCE_CREATE,
                    scopeProperty.ALL_ACCESS,
                  ]}
                >
                  <ResourceTopicView />
                </PrivateRoute>
              }
            />
            <Route
              path='/resource-types/view/:id'
              element={
                <PrivateRoute
                  accessRoles={[
                    scopeProperty.RESOURCE_CREATE,
                    scopeProperty.ALL_ACCESS,
                  ]}
                >
                  <ResourceTypeView />
                </PrivateRoute>
              }
            />
          </Route>
        )}
        <Route path='/no-access' element={<NoAccess />} />
      </Routes>
    </Suspense>
  );
};
