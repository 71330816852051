import React from "react";

interface SearchProps {
  handleSearchChange: ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TagSearch: React.FC<SearchProps> = ({ handleSearchChange }) => {
  return (
    <div className="search-container">
      <div className="col-12">
        <div className="search-box"></div>
        <input
          className="search-input"
          placeholder="Enter a name of a tag"
          onChange={handleSearchChange}
        />
      </div>

      <div className={`secondary-text fw-bold search-transition`}>
        {/* <button className="btn btn-green">
                SEARCH
              </button> */}
      </div>
    </div>
  );
};
