import { useState, useEffect } from 'react';

export interface SeoData {
  [key: string]: {
    title: string;
    description: string;
  };
}

export const useSeoJsonData = (user: any) => {
  const [seoJsonData, setSeoJsonData] = useState<SeoData | null>(null);

  useEffect(() => {
    setSeoJsonData({
      resource: {
        title: 'Resources',
        description: '',
      },
    });
  }, [user]);

  return seoJsonData;
};
