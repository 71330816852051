import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import jwt from 'jwt-decode';

const useAuth = (accessRoles) => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  const jwtDecode: any = jwt(token);
  const userRoles = jwtDecode.scopePermissions || [];

  return userRoles.some((role) => accessRoles.includes(role));
};

export const PrivateRoute = ({ children, accessRoles }) => {
  const location = useLocation();
  const hasAccess = useAuth(accessRoles);

  if (!localStorage.getItem('token')) {
    console.log('****no token****');

    // Redirect them to the /login page, but save the current location they were trying to go to
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return hasAccess ? (
    children
  ) : (
    <Navigate to='/no-access' state={{ from: location }} replace />
  );
};
