const appUrlsJson = {
  ourAppUrls: { formAndQuestinnarie: '/questionnaires' },
  outerDomainUrls: {
    mainParent: {
      home: `${process.env.REACT_APP_MAIA_PORTAL_URL}`,
      logout: `${process.env.REACT_APP_MAIA_PORTAL_URL}/logout`,
    },
  },
};

function checkInnerJson(jsonData: any) {
  if (jsonData) {
    for (const key in jsonData) {
      if (typeof jsonData[key] === 'string') {
        jsonData[key] = `${jsonData[key]}`;
      } else {
        jsonData[key] = checkInnerJson(jsonData[key]);
      }
    }
  }
  return jsonData as typeof appUrlsJson.ourAppUrls;
}

export const appUrls = {
  ...checkInnerJson(appUrlsJson.ourAppUrls),
  outerDomainUrls: appUrlsJson.outerDomainUrls,
};
