/*
reducers are called from context. so look in AuthContext.ts
*/

import { IGlobelSettings, IUserInfo } from '../type/Types';

// the possible values for action.type in
// our AuthReducer fn
export type AuthAction =
  | {
      type: 'LOGIN';
    }
  | {
      type: 'LOAD_USER';
      user: IUserInfo;
    }
  | {
      type: 'USER_SCOPE';
      scopes: any;
    }
  | {
      type: 'MAINPORTAL_SCOPE';
      mainScopes: any;
    }
  | {
      type: 'SET_GLOBAL_SETTINGS';
      globalSettings: IGlobelSettings;
    }
  | {
      type: 'SET_PARENT_SETTINGS';
      parentSettings: any;
    }
  | { type: 'LOGOUT' };

export interface AuthState {
  isAuthenticated: boolean;
  globalSettings: IGlobelSettings;
  parentSettings: any;
  user: IUserInfo | null;
  scopes: any;
  mainScopes: any;
}

// when we hook up the app, the user is not
// going to be present in state.
export const initialState: AuthState = {
  isAuthenticated: false,
  globalSettings: {
    theme: [],
    modules: {
      card_question: false,
      questionnaires: {
        multipleChoice: false,
        statement: false,
        pictureChoice: false,
        freeText: false,
        yesNo: false,
        rating: false,
        likert: false,
        dropdown: false,
        opening: false,
        section: false,
        closing: false,
      },
      card_connect: false,
      card_myHealth: false,
      card_tracker: false,
      card_accessibility: false,
      card_resource: false,
      card_fitbit: false,
      card_oauth: false,
      card_referral: false,
      card_administration: false,
      myHealth: {
        conditions_and_medications: false,
        my_lifestyle: false,
        my_allergies: false,
        appointment: false,
        questionnaires: false,
      },
      tracker: {
        alcohol: false,
        bloodPressure: false,
        bmi: false,
        coping: false,
        diary: false,
        mood: false,
        pain: false,
        waist: false,
        problem: false,
        sleep: false,
        smoking: false,
        goal: false,
        period: false,
        headache: false,
        preHeadache: false,
        temperature: false,
        fitbitStep: false,
        fitbitSleep: false,
        exercise: false,
        dailySteps: false,
        weight: false,
        diet: false,
        fluid: false,
      },
      fitbit: {
        fitbitStep: false,
        fitbitSleep: false,
      },
      connect: {
        connect: false,
      },
      resource: {
        pre_signup_access: false,
      },
      accessibility: {
        skins: false,
        zoom: false,
        colorSaturation: false,
        letterSpacing: false,
        higlightLink: false,
        lineheight: false,
        bigCursor: false,
        fontSize: false,
        headingFont: false,
        baseFont: false,
        readingGuide: false,
        tooltip: false,
        readingMask: false,
        voiceNavigation: false,
        voiceOverview: false,
        voiceNavigationOverview: false,
        pageStructure: false,
        hideImage: false,
        pageStructureOpen: false,
        helpGuide: false,
        dictionary: false,
      },
      oauth: {
        google: false,
        nhs: false,
        azure: false,
      },
      referral: {
        pre_signup_access: false,
        referral_share: false,
        contributors: false,
      },
      portalAdministration: {
        module_management: false,
        role_management: false,
        user_management: false,
        area_management: false,
        service_management: false,
      },
    },
    siteConfigs: {
      pagination: 10,
      dateFormat: {
        time: 'hh:mm A',
        date: 'DD MMM YYYY',
        dateOverview: 'ddd, DD MMM YYYY',
        dateTime: 'dddd, DD MMM YYYY, hh:mm A',
        dateInput: 'DD/MM/YYYY',
      },
    },
  },
  parentSettings: null,
  user: null,
  scopes: null,
  mainScopes: null,
};

const siteConfigs = {
  pagination: 10,
  dateFormat: {
    time: 'hh:mm A',
    date: 'DD MMM YYYY',
    dateOverview: 'ddd, DD MMM YYYY',
    dateTime: 'dddd, DD MMM YYYY, hh:mm A',
    dateInput: 'DD/MM/YYYY',
  },
};
// a reducer give back a state
export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, isAuthenticated: true };
    case 'LOAD_USER': {
      return { ...state, user: action.user };
    }
    case 'USER_SCOPE': {
      return { ...state, scopes: action.scopes };
    }
    case 'MAINPORTAL_SCOPE': {
      return { ...state, mainScopes: action.mainScopes };
    }
    case 'SET_GLOBAL_SETTINGS': {
      return {
        ...state,
        globalSettings: { ...action.globalSettings, siteConfigs },
      };
    }
    case 'SET_PARENT_SETTINGS': {
      return { ...state, parentSettings: action.parentSettings };
    }
    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }
    default:
      return state;
  }
};
