import React, { useState } from 'react';
import './tooltip.css';

interface TooltipProps {
  delay?: number;
  direction?: string;
  content: React.ReactNode;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({
  delay = 400,
  direction = 'top',
  content,
  children,
}) => {
  let hoverTimeout: NodeJS.Timeout;
  let focusTimeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    clearTimeout(focusTimeout);
    hoverTimeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearTimeout(hoverTimeout);
    setActive(false);
  };

  const handleFocus = () => {
    clearTimeout(hoverTimeout);
    focusTimeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const handleBlur = () => {
    clearTimeout(focusTimeout);
    setActive(false);
  };

  return (
    <div
      className='Tooltip-Wrapper'
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {children}
      {active && <div className={`Tooltip-Tip ${direction}`}>{content}</div>}
    </div>
  );
};

export default Tooltip;
