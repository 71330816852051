import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ResourceList from './ResourceList';
import ResourceView from './ResourceView';
import ResourceForm from './ResourceForm';
import { PrivateRoute } from '../../routes/PrivateRoute';
import { scopeProperty } from '../../global/scopePayload';
import './Resource.css';

const Resource = () => {
  return (
    <div className='resource'>
      <Routes>
        <Route
          path='/'
          element={
            <PrivateRoute
              accessRoles={[
                scopeProperty.RESOURCE_VIEW,
                scopeProperty.RESOURCE_CREATE,
                scopeProperty.RESOURCE_APPROVE,
                scopeProperty.RESOURCE_PUBLISH,
                scopeProperty.RESOURCE_PUBLISHED_VIEW,
                scopeProperty.ALL_ACCESS,
              ]}
            >
              <ResourceList />
            </PrivateRoute>
          }
        />
        <Route
          path={`/view/:id`}
          element={
            <PrivateRoute
              accessRoles={[
                scopeProperty.RESOURCE_VIEW,
                scopeProperty.RESOURCE_CREATE,
                scopeProperty.RESOURCE_APPROVE,
                scopeProperty.RESOURCE_PUBLISH,
                scopeProperty.ALL_ACCESS,
                scopeProperty.RESOURCE_PUBLISHED_VIEW,
              ]}
            >
              <ResourceView />
            </PrivateRoute>
          }
        />
        <Route
          path={`/edit/:id`}
          element={
            <PrivateRoute accessRoles={[scopeProperty.RESOURCE_CREATE]}>
              <ResourceForm />
            </PrivateRoute>
          }
        />
        <Route
          path={`/create`}
          element={
            <PrivateRoute accessRoles={[scopeProperty.RESOURCE_CREATE]}>
              <ResourceForm />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default Resource;
