import React, { useState, createContext, useContext, useEffect } from 'react';

import useAuthContext from './AuthContext';
import jwtDecode from 'jwt-decode';

const PermissionContext = createContext<any>({});

export const PermissionContextProvider = (
  props: React.PropsWithChildren<{}>
) => {
  const {
    state: { user, mainScopes },
  }: any = useAuthContext();

  const [scopeArray, setScopeArray] = useState<any>([]);
  const [scopeMenuLoaded, setScopeMenuLoaded] = useState<boolean>(false);

  const setScopeValue = () => {
    const result: any = mainScopes || [];

    // console.log('ScopeArray', result);

    setScopeArray(result);
  };

  const [scopes, setScopes] = useState<any>([]);

  // set scope values
  const getScopeValues = (permissionData: any) => {
    if (permissionData) {
      setScopes(permissionData);
    }
  };

  //  check permission
  const checkPermissions = (scopes: any) => {
    // console.log('scopes', scopeArray, scopes);
    let result = scopeArray.some((item: any) => scopes.indexOf(item) >= 0);

    return result;
  };

  useEffect(() => {
    setScopeValue();
  }, [user]);

  useEffect(() => {
    setScopeMenuLoaded(scopeArray && scopeArray.length > 0);
  }, [scopeArray]);

  return (
    <PermissionContext.Provider
      value={{
        scopes,
        scopeMenuLoaded,
        getScopeValues,
        checkPermissions,
      }}
      {...props}
    />
  );
};

export default function usePermissionContext() {
  return useContext(PermissionContext);
}
