import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";
import jwt from "jwt-decode";

export const loginUser = createAsyncThunk(
  "auth/login",
  async ({ email, password }: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: "/api/user/login",
        data: {
          username: email,
          password,
        },
      });
      let data = await response.data.data;
      if (response.status === 200) {
        let jwtDecode: any = jwt(data.token);
        localStorage.setItem("username", jwtDecode.name);
        localStorage.setItem("token", data.token);
        return data;
      } else if (response.status === 201) {
        return thunkAPI.rejectWithValue(response.data as any);
      } else {
        return thunkAPI.rejectWithValue(response as any);
      }
    } catch (e: any) {
      console.log("error", e.message)
      // console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const userForgotpassword = createAsyncThunk(
  "user/forgotpassword",
  async ({ email }: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: "/api/user/forgotpassword",
        data: {
          email: email,
        },
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const checkTokenValid = createAsyncThunk(
  "user/checkTokenValid",
  async ({ token }: any, thunkAPI) => {
    try {
      let url = "/api/user/validate-token/" + token;
      const response = await axios({
        method: "POST",
        url: url,
        data: {},
      });
      let data = await response.data.data;
      if (response.status === 200) {
        return response.status;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateTokenPassword = createAsyncThunk(
  "user/updateTokenPassword",
  async ({ token, password }: any, thunkAPI) => {
    try {
      let url = "/api/user/password-reset/" + token;
      const response = await axios({
        method: "POST",
        url: url,
        data: {
          password: password,
        },
      });
      let data = await response.data.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const findOrInsertUser = createAsyncThunk(
  "user/findOrInsertUserOauth",
  async (userTkn: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "get",
        url: `/api/oauth/login-check?userTkn=${userTkn}`,
      }
      );
      let data = await response.data.data;
      console.log("🚀 ~ file: AuthSlice.ts:120 ~ data:", response)
      if (response.status === 200) {
        let jwtDecode: any = jwt(data.token);
        localStorage.setItem("username", jwtDecode.name);
        localStorage.setItem("token", data.token);
        localStorage.setItem("trustLogo", data.trustLogo);
        localStorage.setItem("trustTheme", JSON.stringify(data.trustTheme));
        return data;
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const authSlice = createSlice({
  name: "auth",
  initialState: {
    username: "",
    email: "",
    isLoggedIn: localStorage.getItem("token") ? true : false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    trustLogo: localStorage.getItem("trustLogo") || "",
    trustTheme: localStorage.getItem("trustTheme")||""
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
    logOutUser: (state) => {
      window.localStorage.clear();
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.email = action.payload.email;
        state.username = action.payload.name;
        state.isLoggedIn = true;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(loginUser.rejected, (state, action: any) => {
        state.errorMessage = action?.payload?.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(userForgotpassword.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(userForgotpassword.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        // state.user = action.payload.data;
      })
      .addCase(userForgotpassword.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(findOrInsertUser.fulfilled, (state, action) => {
        state.trustLogo = action.payload.trustLogo
        state.trustTheme = action.payload.trustTheme
      });
  },
});

export const { clearState, logOutUser } = authSlice.actions;

export const userSelector = (state: RootState) => state.auth;
