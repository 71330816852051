export const TableCustomStyle = {
  table: {
    style: {
      color: "rgba(var(--baseColor) / 1)",
      border: "1px solid rgba(var(--baseColor) / 0.5)",
      borderRadius: "8px",
    },
  },
  cells: {
    style: {
      color: "rgba(var(--baseColor) / 1) !important",
      "&:last-child": {
        justifyContent: "flex-end",
        paddingRight: "16px",
      },
    },
  },
  headRow: {
    style: {
      backgroundColor: "rgba(var(--primary) / 0.1) !important",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
      border: "none",
      minHeight: "70px",
      color: "black !important",
    },
  },
  headCells: {
    style: {
      fontWeight: "600 !important",
      "&:last-child": {
        justifyContent: "flex-end",
        paddingRight: "16px",
      },
    },
  },
  rows: {
    style: {
      border: "none",
      minHeight: "70px",
      backgroundColor: "rgba(var(--neutral) / 1)",
      color: "rgba(var(--baseColor) / 1)",
      cursor: "default",
      "&:hover": {
        backgroundColor: "rgba(var(--primary) / 0.1) !important",
        cursor: "default !important",
        border: "none !important",
        outline: "none !important",
      },
    },
  },
  rdt_ExpanderRow: {
    style: {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  },
  expanderRow: {
    style: {
      color: "rgba(var(--baseColor) / 1) !important",
      backgroundColor: "#fff !important",
    },
  },
  expanderCell: {
    style: {
      display: "flex",
      flex: "0 0 48px",
      backgroundColor: "transparent",
      borderRadius: "100%",
      marginLeft: "16px",
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "rgba(var(--baseColor) / 0.1) !important",
      borderRadius: "100%",
      transition: "0.25s",
      height: "50px",
      width: "50px",
      right: 0,
      "&:hover:enabled": {
        cursor: "pointer",
      },
      svg: {
        margin: "auto",
      },
    },
  },
};
