import React, { useEffect } from 'react';
import $ from 'jquery';
import { ToastContainer } from 'react-toastify';
import { AuthContextProvider } from './context/provider/AuthContext';
import { PermissionContextProvider } from './context/provider/PermissionContext';

import { BrowserRouter, useLocation } from 'react-router-dom';
import { AppRoutes } from './routes';
import { useAppSelector } from './app/hooks';
import { userSelector } from './features/Auth/AuthSlice';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  $(document).ready(function () {
    function isEmpty(el: any) {
      return !$.trim(el.text());
    }
    $('h1,h2,h3,h4,h5,h6,label,p').each((t: any, e: any) => {
      if (isEmpty($(e))) {
        // console.log($(e), "is empty");
        $(e).append('&#8203;');
      }
    });
  });

  const defaultThemeObj: any = {
    primary: '81 121 111',
    'primary-content': '255 255 255',
    secondary: '54 79 82',
    'secondary-content': '255 255 255',
    accent: '55 165 144',
    'accent-content': '255 255 255',
    'button-primary': '81 121 111',
    'btn-primary-content': '255 255 255',
    'btn-primary-states': '81 121 111',
    'button-secondary': '255 255 255',
    'btn-secondary-content': '81 121 111',
    'btn-secondary-states': '3 60 124',
    navbar: '54 79 82',
    'navbar-content': '255 255 255',
    header: '248 248 248',
    'header-content': '54 79 82',
    'header-icon': '54 79 82',
    baseColor: '49 49 49',
    titleColor: '81 121 111',
    body: '248 248 248',
    card: '248 248 248',
    neutral: '255 255 255',
    outlineColor: '191 191 191',
    info: '37 99 235',
    success: '18 110 52',
    warning: '217 119 6',
    error: '220 38 38',
    disabled: '233 236 239',
    transparent: 'transparent',
    'mood-verypoorly': '#f99f2c',
    'mood-poor': '#ffda47',
    'mood-alright': '#84acd9',
    'mood-well': '#a4bc39',
    'mood-verywell': '#4b9f88',
    'font-header': "'Montserrat', 'sans-serif'",
    'font-body': "'Montserrat', 'sans-serif'",
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        console.log('Enter key was pressed. Run your function.');
        event.preventDefault();
        // callMyFunction();
        const el = $(event.target);

        if ($(el).hasClass('focusto')) {
          if ($(el).attr('focus-to')) {
            // remove attr
            $('[focus-back-element="true"]').each(function (i, e) {
              $(e).removeAttr('focus-back-element');
            });
          }
          if (!$(el).hasClass('question-menu-block')) {
            $('.focusback_btn').removeClass('flex');
            $('.focusback_btn').addClass('hidden');
          }
          if (!$(el).hasClass('user-role-section')) {
            $('.focusback_btn').removeClass('flex');
            $('.focusback_btn').addClass('hidden');
          }

          if (
            $('[focus-back-element="true"]').length === 0 &&
            $(el).attr('focus-to')
          ) {
            console.log('----------no element------------');

            if ($(el).hasClass('question-menu-info')) {
              $('.focusback_btn').removeClass('hidden');
              $('.focusback_btn').addClass('flex');
            }
            if ($(el).hasClass('user-role-section')) {
              $('.focusback_btn').removeClass('hidden');
              $('.focusback_btn').addClass('flex');
            }

            if ($(el).hasClass('setting_popup_focus')) {
              setTimeout(function () {
                console.log(
                  '------rrrrrrrrrrrr--------',
                  $('.setting_back_focus')
                );
                $('.setting_back_focus').removeClass('hidden');
              }, 1000);
              $('.setting_back_focus').addClass('flex');
            }

            // add identity to element
            $(el).attr('focus-back-element', 'true');
          } else {
            console.log(
              '----------element--exist----------',
              $('[focus-back-element="true"]').length
            );
            // get element
            const element: any = $('[focus-back-element="true"]')[0];
            if (element) {
              console.log('focus-back-element', element);
              element.focus();

              if ($(el).hasClass('focusback_btn')) {
                $('.focusback_btn').removeClass('flex');
                $('.focusback_btn').addClass('hidden');
              }
              if ($(el).hasClass('setting_back_focus')) {
                console.log('-setting_back_focus- 2', el);

                $('.setting_back_focus').removeClass('flex');
                $('.setting_back_focus').addClass('hidden');
              }

              if ($(el).attr('focus-to') && !$(el).attr('focus-back-element')) {
                // remove attr
                $('[focus-back-element="true"]').each(function (i, e) {
                  $(e).removeAttr('focus-back-element');
                });
                $('.focusback_btn').each(function (i, e) {
                  $(e).removeClass('flex');
                  $(e).addClass('hidden');
                });
              }

              // remove attr
              $('[focus-back-element="true"]').each(function (i, e) {
                $(e).removeAttr('focus-back-element');
              });
            }
          }

          el.trigger('click');

          if (
            el.attr('focus-to') &&
            document.getElementById(el.attr('focus-to') || 'null')
          ) {
            const focusToEl = document.getElementById(
              el.attr('focus-to') || 'null'
            );
            if (focusToEl) {
              focusToEl.focus();
            }
          }
        }

        const elm = $(event.target);

        if ($(elm).hasClass('onkeyup')) {
          el.trigger('click');
        }
      }
    };
    document.addEventListener('keyup', listener);
    return () => {
      document.removeEventListener('keyup', listener);
    };
  }, []);

  const ScrollTop = ({ children }) => {
    const { pathname } = useLocation();

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
      //  window.scrollTo(0, 0);
    }, [pathname]);
    return <>{children}</>;
  };

  const { isLoggedIn } = useAppSelector(userSelector);
  let { trustTheme } = useAppSelector(userSelector);
  async function updateThemeValues(themeConfig: any) {
    const styleProperties = document.documentElement;
    const configArrColor = themeConfig.color;
    const configArrFont = themeConfig.font;
    Object.entries(configArrColor).forEach(([key, value]: any) => {
      Object.entries(configArrColor[key]).forEach(
        ([colorObj, colorVal]: any) => {
          styleProperties.style.setProperty(
            `--${configArrColor[key][colorObj].name}`,
            `${configArrColor[key][colorObj].rgba.r} ${configArrColor[key][colorObj].rgba.g} ${configArrColor[key][colorObj].rgba.b}`
          );
        }
      );
    });
    Object.entries(configArrFont).forEach(([key, value]: any) => {
      Object.entries(configArrFont[key]).forEach(([fontObj, fontVal]: any) => {
        if (key === 'primary_font') {
          styleProperties.style.setProperty(`--font-header`, fontVal);
        }
        if (key === 'secondary_font') {
          styleProperties.style.setProperty(`--font-body`, fontVal);
          styleProperties.style.setProperty(
            `--banner`,
            'url(/static/media/banner.78f444fa.svg)'
          );
        }
      });
    });
  }

  useEffect(() => {
    let theme = [];
    try {
      theme = JSON.parse(trustTheme);
    } catch (error) {
      console.log('parse theme error.....', error);
    }
    if (theme && theme.length > 0) {
      updateThemeValues(theme[0]);
    } else {
      const styleProperties = document.documentElement;
      Object.entries(defaultThemeObj).forEach(([colorObj, colorVal]: any) => {
        styleProperties.style.setProperty(
          `--${colorObj}`,
          `${defaultThemeObj[colorObj]}`
        );
      });
    }
  }, []);

  return (
    <AuthContextProvider>
      <PermissionContextProvider>
        <BrowserRouter>
          <div className='App'>
            <main className='mx-auto' role='main' id='main-content'>
              <AppRoutes />
            </main>
            <ToastContainer limit={1} pauseOnHover={false} autoClose={3000} />
          </div>
        </BrowserRouter>
      </PermissionContextProvider>
    </AuthContextProvider>
  );
}

export default App;
