import { useState, useEffect } from 'react';

export interface SeoData {
  [key: string]: {
    title: string;
    description: string;
  };
}

export const useHeadingJson = (user: any) => {
  const [headingJson, setHeadingJson] = useState<SeoData | null>(null);

  useEffect(() => {
    setHeadingJson({
      resource: {
        title: 'Resources',
        description:
          'Update your resource information, manage your users and resource library',
      },
      notificationcentre: {
        title: 'Notification',
        description:
          'Update your resource information, manage your users and resource library',
      },
    });
  }, [user]);

  return headingJson;
};
