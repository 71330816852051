import React, { useState } from 'react';
import PrismCode from 'react-prism';
import 'prismjs';
import 'prismjs/components/prism-jsx.min';
import 'prismjs/themes/prism-okaidia.css';
import Logo from '../../assets/images/amity__logo.svg';
import MindwaveLogo from '../../assets/images/mindwave-logo.svg';

import Back from '../../assets/images/back.svg';

import '../../styles/api.css';
import code from './Code';
import { ApiAccordion } from '../../components/Api/ApiAccordion';

import axios from 'axios';
import { APIDoc } from './APIDoc';
import JsonFormatter from 'react-json-formatter';
import Loading from '../../components/loading/Loading';

interface ResourcesApiProps {}

export const ResourcesApi: React.FC<ResourcesApiProps> = () => {
  const [response, setResponse] = useState<any>({
    status: true,
    message: 'Access Token Generated',
    data: {
      accessToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvQXV0aF9pZCI6IjYyZWJjMTA0ZTc4Y2QxNzYxNWU5MDM1MyIsImNsaWVudElkIjoiYjBiZWMzNmI2ZGQzMGU2MSIsImlhdCI6MTY1OTYzMDE2NiwiZXhwIjoxNjU5NzE2NTY2fQ.B9m5tPHWgeIzWDp1xhInjdrNi91D2Vd9XXatkeg4A_I',
      refreshToken: '49069b5c2d2a8c9b79d47e1d',
      validUpto: '2022-08-05T16:22:46.000Z',
    },
  });
  const [activeMainAPI, setActiveMainAPI] = useState<any>({});
  const [activeAPI, setActiveAPI] = useState<any>();
  const [queryParams, setQueryParams] = useState<any>(`{
    "page": 1,
    "perPage" :5,
    "sortBy": "title",
    "sortOrder": -1,
    "filter": {"tags" : "Health,Sugar"}
}`);
  const [message, setvalidation] = useState<any>();
  const [check, setcheck] = useState<any>([]);
  const [enableInput, setEnableInput] = useState<any>({});
  const [queryData, setQueryData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accordionId, setAccordionId] = useState<any>();
  console.log('checkk', accordionId);
  function isJsonString(queryParams: any) {
    console.log(
      '🚀 ~ file: ResourcesApi.tsx ~ line 49 ~ isJsonString ~ queryParams',
      queryParams
    );
    try {
      console.log('JSONPArse', JSON.parse(JSON.stringify(queryParams)));
      return JSON.parse(JSON.stringify(queryParams));
    } catch (e) {
      console.log(
        '🚀 ~ file: ResourcesApi.tsx ~ line 54 ~ isJsonString ~ e',
        e
      );
      return false;
    }
  }
  const handlAPIcall = async (data: any, index: number) => {
    console.log(
      '🚀 ~ file: ResourcesApi.tsx ~ line 63 ~ handlAPIcall ~ data',
      data
    );
    let queryVars = JSON.stringify(queryData)
      .replaceAll('"', '')
      .replaceAll(',', '&')
      .replaceAll(':', '=')
      .replaceAll('{', '')
      .replaceAll('}', '');
    let JSONObj = await isJsonString(queryParams);
    console.log('JSONObj type', typeof JSONObj);
    console.log('JSONObj', JSONObj);

    let bodyData,
      isDirty = false;
    if (JSONObj === false) {
      setvalidation('Please Enter Valid JSON');
      isDirty = true;
    } else {
      isDirty = false;
      bodyData = JSONObj;
    }
    if (isDirty === false && activeAPI !== '') {
      setIsLoading(true);
      await axios({
        method: data?.methodType,
        url: `${data?.url}${data.queryParams.length ? queryVars : ''}`,
        headers: {},
        data: data.body && bodyData,
      })
        .then(function (response) {
          setIsLoading(false);
          console.log(JSON.stringify(response.data));
          setResponse(response?.data);
          if (response?.data?.data?.accessToken) {
            localStorage.setItem('token', response?.data?.data?.accessToken);
          }
        })
        .catch(function (error) {
          setIsLoading(false);
          console.log(error);
          setResponse(error);
        });
    }
  };

  const handleReset = () => {
    setResponse('');
    setQueryParams({});
    setQueryData({});
  };
  const jsonStyle = {
    propertyStyle: { color: 'red' },
    stringStyle: { color: 'blue' },
    numberStyle: { color: 'blue' },
  };
  return (
    <>
      <div className='sandbox-main-container'>
        <a href='/'>
          <span className='back'>
            <img src={Back} alt='Back Icon' />
          </span>
        </a>
        <div className='mail-logo-div'>
          <img className='phr-logo' src={Logo} />
        </div>
        <div className='sandbox-container'>
          {APIDoc.length &&
            APIDoc?.map((item: any, mainIndex) => {
              return (
                <ApiAccordion
                  head={`${item.name}`}
                  onAction={(e) => setActiveAPI(-1)}
                  mainIndex={mainIndex}
                  accordionId={accordionId}
                  setAccordionId={setAccordionId}
                >
                  <div className='accordion-body'>
                    {item?.desc?.length &&
                      item?.desc?.map((data, index) => {
                        return (
                          <div className='api-card-block'>
                            <h1>{data?.title}</h1>
                            <p className='mb-0'>{data?.desc}</p>
                          </div>
                        );
                      })}
                    <div className='api-section'>
                      {/* <!-- api-input-block start --> */}
                      <div className='api-input-block'>
                        <h1>{item?.APIs?.mainTitle}</h1>
                        {item?.APIs?.APIList?.length &&
                          item?.APIs?.APIList?.map((api, index) => {
                            {
                              /* <!-- api-input-group start --> */
                            }
                            return (
                              <div className='api-input-group'>
                                <input
                                  type='radio'
                                  name='api'
                                  id={`get-api-${activeAPI}`}
                                  checked={
                                    activeMainAPI?.mainId === mainIndex &&
                                    activeMainAPI?.id === index
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  htmlFor={`get-api-${item.name}-${index}`}
                                  onClick={() => {
                                    console.log('index', index);
                                    setActiveMainAPI({
                                      mainId: mainIndex,
                                      id: index,
                                    });
                                    handleReset();
                                    setcheck([]);
                                    setActiveAPI(index);
                                    setQueryParams(
                                      item?.APIs?.APIList[index].queryParams
                                    );
                                    setResponse(
                                      JSON.stringify(
                                        item?.APIs?.APIList[index]
                                          .sampleResponses
                                      )
                                    );
                                  }}
                                >
                                  {api?.name}
                                </label>
                              </div>
                            );
                            {
                              /* <!-- api-input-group end --> */
                            }
                          })}
                      </div>
                      {/* <!-- api-input-block end --> */}

                      {/* <!-- api-return-data-block --> */}
                      <div className='api-return-data-block'>
                        <div className='api-data-heading'>
                          {/* <h1>{item?.APIs?.APIList[activeAPI]?.title}</h1>
                          <p>{item?.APIs?.APIList[activeAPI]?.desc}</p> */}
                        </div>

                        {/* <!-- api-data-group start --> */}
                        {activeAPI >= 0 && (
                          <div className='api-data-group'>
                            <h1>{item?.APIs?.APIList[activeAPI]?.urlTitle}</h1>
                            <p>{item?.APIs?.APIList[activeAPI]?.urlDesc}</p>
                            <div className='api-base-output'>
                              <span>
                                {item?.APIs?.APIList[activeAPI]?.methodType}{' '}
                                {item?.APIs?.APIList[activeAPI]?.url}
                              </span>
                            </div>
                          </div>
                        )}

                        {typeof item?.APIs?.APIList[activeAPI]?.queryParams
                          ?.length == 'number' &&
                        item?.APIs?.APIList[activeAPI]?.queryParams?.length !==
                          0 ? (
                          <div className='api-headers ry'>
                            {activeAPI >= 0 && (
                              <div className='api-parameter-block'>
                                <span>
                                  <h1>
                                    {
                                      item?.APIs?.APIList[activeAPI]
                                        ?.authAndHeaderTitle
                                    }
                                  </h1>
                                  <p>
                                    {
                                      item?.APIs?.APIList[activeAPI]
                                        ?.authAndHeaderDesc
                                    }
                                  </p>
                                </span>
                                <span>
                                  <button
                                    onClick={() => {
                                      check?.includes(activeAPI)
                                        ? setcheck(
                                            check.filter((a) => a !== activeAPI)
                                          )
                                        : setcheck((prev) => [
                                            ...prev,
                                            activeAPI,
                                          ]);
                                      setEnableInput(mainIndex);
                                    }}
                                    className='btn api-btn'
                                  >
                                    {check.includes(activeAPI) &&
                                    mainIndex === enableInput
                                      ? 'Disable'
                                      : 'Enable'}
                                  </button>
                                </span>
                              </div>
                            )}
                            <div className='api-base-output'>
                              {activeAPI >= 0 && (
                                <div className='api-auth-key-block'>
                                  <div>
                                    <h2>Header key</h2>
                                    {item?.APIs?.APIList[activeAPI]?.queryParams
                                      ?.length &&
                                      item?.APIs?.APIList[
                                        activeAPI
                                      ]?.queryParams?.map((item) => {
                                        return <p>{item}</p>;
                                      })}
                                  </div>

                                  <div>
                                    <h2>Header value</h2>
                                    {item &&
                                      item?.APIs?.APIList[activeAPI]
                                        ?.queryParams?.length &&
                                      item?.APIs?.APIList[
                                        activeAPI
                                      ]?.queryParams?.map((item) => {
                                        return (
                                          <input
                                            type='text'
                                            name={`${item}`}
                                            value={
                                              queryData?.[item]
                                                ? queryData?.[item]
                                                : ''
                                            }
                                            onChange={(e) =>
                                              setQueryData((preValue) => ({
                                                ...preValue,
                                                [e.target.name]: e.target.value,
                                              }))
                                            }
                                            disabled={
                                              !(
                                                check.includes(activeAPI) &&
                                                mainIndex === enableInput
                                              )
                                            }
                                          />
                                        );
                                      })}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='block-right'>
                              <div className='api-auth-btn-block'>
                                <button
                                  onClick={() => handleReset()}
                                  className='btn api-btn'
                                  disabled={
                                    !(
                                      check.includes(activeAPI) &&
                                      mainIndex === enableInput
                                    )
                                  }
                                >
                                  Reset
                                </button>
                                <button
                                  onClick={() =>
                                    handlAPIcall(
                                      item?.APIs?.APIList[activeAPI],
                                      activeAPI
                                    )
                                  }
                                  className='btn api-btn'
                                  disabled={
                                    !(
                                      check.includes(activeAPI) &&
                                      mainIndex === enableInput
                                    )
                                  }
                                >
                                  Get
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : item?.APIs?.APIList[activeAPI]?.body ? (
                          <div className='api-headers'>
                            <div className='api-parameter-block'>
                              <span>
                                <h1>Parameters</h1>
                                <p>
                                  API parameters are the variable parts of a
                                  resource
                                </p>
                              </span>
                              <span>
                                <button
                                  //  onClick={() => {
                                  //   setEnableInput(mainIndex)
                                  // }}
                                  onClick={() => {
                                    check?.includes(activeAPI)
                                      ? setcheck(
                                          check.filter((a) => a !== activeAPI)
                                        )
                                      : setcheck((prev) => [
                                          ...prev,
                                          activeAPI,
                                        ]);
                                    setEnableInput(mainIndex);
                                  }}
                                  className='btn api-btn'
                                >
                                  {check.includes(activeAPI) &&
                                  mainIndex === enableInput
                                    ? 'Disable'
                                    : 'Enable'}
                                </button>
                              </span>
                            </div>
                            <section>
                              <textarea
                                name={`${item}`}
                                id={`${item + activeAPI}`}
                                disabled={
                                  !(
                                    check.includes(activeAPI) &&
                                    mainIndex === enableInput
                                  )
                                }
                                value={
                                  typeof queryParams === 'string'
                                    ? queryParams
                                    : JSON.stringify(queryParams)
                                }
                                onChange={(e) => {
                                  setQueryParams(e.target.value);
                                  setvalidation('');
                                }}
                              />
                            </section>
                            <span style={{ color: 'red' }}>{message}</span>
                            <div className='block-right'>
                              <div className='api-auth-btn-block'>
                                <button
                                  onClick={() => handleReset()}
                                  className='btn api-btn'
                                  disabled={
                                    !(
                                      check.includes(activeAPI) &&
                                      mainIndex === enableInput
                                    )
                                  }
                                >
                                  Reset
                                </button>
                                <button
                                  onClick={() =>
                                    handlAPIcall(
                                      item?.APIs?.APIList[activeAPI],
                                      activeAPI
                                    )
                                  }
                                  className='btn api-btn'
                                  disabled={
                                    !(
                                      check.includes(activeAPI) &&
                                      mainIndex === enableInput
                                    )
                                  }
                                >
                                  Get
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className='api-headers'>
                              <div className='api-parameter-block'>
                                <span>
                                  {activeAPI >= 0 && (
                                    <button
                                      onClick={() => {
                                        check?.includes(activeAPI)
                                          ? setcheck(
                                              check.filter(
                                                (a) => a !== activeAPI
                                              )
                                            )
                                          : setcheck((prev) => [
                                              ...prev,
                                              activeAPI,
                                            ]);
                                        setEnableInput(mainIndex);
                                      }}
                                      className='btn api-btn'
                                    >
                                      {check.includes(activeAPI) &&
                                      mainIndex === enableInput
                                        ? 'Disable'
                                        : 'Enable'}
                                    </button>
                                  )}
                                </span>
                                {activeAPI >= 0 && (
                                  <div className='block-right'>
                                    <div className='api-auth-btn-block'>
                                      <button
                                        onClick={() =>
                                          handlAPIcall(
                                            item?.APIs?.APIList[activeAPI],
                                            activeAPI
                                          )
                                        }
                                        className='btn api-btn'
                                        disabled={
                                          !(
                                            check.includes(activeAPI) &&
                                            mainIndex === enableInput
                                          )
                                        }
                                      >
                                        Get
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className='api-headers'>
                          {response && <h1>Responses</h1>}
                          <div className='code-container d-none'>
                            {/* <PrismCode className="language-jsx" children={code} /> */}
                            {/* <textarea
                                  name=""
                                  id=""
                                  value={JSON.stringify(response)}
                                ></textarea> */}
                          </div>
                        </div>
                        {item?.APIs?.APIList[activeAPI] &&
                          item?.APIs?.APIList[activeAPI].sampleResponses && (
                            <div className='prettier-code'>
                              <JsonFormatter
                                json={JSON.stringify(response)}
                                tabWith={4}
                                jsonStyle={jsonStyle}
                              />
                            </div>
                          )}
                        {/* <!-- api-data-group end --> */}

                        {/* <!-- api-Sample-Response start --> */}
                        <div className='api-headers'>
                          <h1>Sample Response</h1>
                          <div className='code-container custom-table'>
                            <table>
                              <thead>
                                <tr>
                                  <th>Code</th>
                                  <th>Description</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>200</td>
                                  <td>ok</td>
                                </tr>
                                <tr>
                                  <td>201</td>
                                  <td>created</td>
                                </tr>
                                <tr>
                                  <td>400</td>
                                  <td>Bad request</td>
                                </tr>
                                <tr>
                                  <td>401</td>
                                  <td>
                                    Authorisation information missing or invalid
                                  </td>
                                </tr>
                                <tr>
                                  <td>404</td>
                                  <td>Not found</td>
                                </tr>
                                <tr>
                                  <td>5XX</td>
                                  <td>Unexpected Error</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <!-- api-Sample-Response end --> */}

                        {/* <!-- api-data-group start --> */}
                        {/* <div className="api-headers">
                    <h1>Endpoints</h1>
                    <p>
                      An endpoint is added to the base URL to create the full
                      request URL. Some APIs have more than 1 type of endpoint
                      you can use.
                    </p>
                    <div className="api-base-output">
                      <div className="api-endpoint-section">
                        <p>
                          The API version number can be supplied as a query
                          parameter:
                        </p>
                        <div className="api-endpoint-block">
                          <h2 className="mb-4">Health topic</h2>
                          <p>
                            Includes information and advice about hundreds of
                            conditions, symptoms and treatments. This content is
                            available as whole web pages, as well as some
                            smaller, more specific sections (modules).
                          </p>
                          <div className="api-endpoint-data">
                            <h2>Endpoint</h2>
                            <div>
                              <span>GET</span>
                              <span>/*</span>
                            </div>
                          </div>
                          <div className="api-parameters-block">
                            <h2>Parameters</h2>
                            <div className="parameter-info">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Required</th>
                                    <th>Allowed</th>
                                    <th>Default</th>
                                    <th>Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <h2>modules</h2>
                                    </td>
                                    <td>boolean</td>
                                    <td>true</td>
                                    <td>true</td>
                                    <td>true</td>
                                    <td>
                                      <p>
                                        Displays only the modules for a health
                                        topic when available. Available options:
                                        true, false. Default false.
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="api-responses">
                            <h2>Responses</h2>
                            <h2>
                              200 - Successful response for a health topic
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                        {/* <!-- api-data-group end --> */}
                      </div>
                    </div>
                  </div>
                </ApiAccordion>
              );
            })}
          <div className='ft-logo'>
            <img src={MindwaveLogo} alt='MindwaveLogo' />
            <a
              className='api-link-menu'
              href='/terms-and-conditions'
              target='_blank'
            >
              Terms and conditions
            </a>
          </div>
          {isLoading && <Loading status={true} />}
        </div>
      </div>
    </>
  );
};
