export const decodeEntities = (encodedString: string) => {
  const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  const translate = {
    "nbsp": " ",
    "amp": "&",
    "quot": "\"",
    "lt": "<",
    "gt": ">"
  };
  const str = encodedString.replace(translate_re, function (match, entity) {
    return translate[entity];
  }).replace(/&#(\d+);/gi, function (match, numStr) {
    var num = parseInt(numStr, 10);
    return String.fromCharCode(num);
  });
  // console.log('encode str :>>', encodedString);
  // console.log('decode str :>>', str);
  return str;
}