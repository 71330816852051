export const scopes = {
  appointment: {
    appTypeView: 'appointment_type:view',
    appTypeUpdate: 'appointment_type:update',
    appTypeSave: 'appointment_type:save',
    appTypeDelete: 'appointment_type:delete',
  },
  notification: {
    notificationView: 'notification:view',
    notificationUpdate: 'notification:update',
    notificationSave: 'notification:save',
    notificationSchedule: 'notification:schedule',
    notificationDelete: 'notification:delete',
  },
};

export const scopeNames = {
  appointment: {
    appointment_type: {
      view: 'appointment_type:view',
      create: 'appointment_type:save',
      edit: 'appointment_type:update',
      delete: 'appointment_type:delete',
    },
    diagnosis_type: {
      view: 'diagnosis_type:view',
      create: 'diagnosis_type:save',
      approve: 'diagnosis_type:approve',
      edit: 'diagnosis_type:update',
      delete: 'diagnosis_type:delete',
    },
    clinic: {
      view: 'clinic:view',
      create: 'clinic:save',
      edit: 'clinic:update',
      delete: 'clinic:delete',
    },
    clinic_location: {
      view: 'clinic_location:view',
      create: 'clinic_location:save',
      edit: 'clinic_location:update',
      delete: 'clinic_location:delete',
    },
    appointment_status: {
      view: 'appointment_status:view',
      create: 'appointment_status:save',
      edit: 'appointment_status:update',
      delete: 'appointment_status:delete',
    },
    manage_appointment: {
      view: 'manage_appointment:view',
      book: 'manage_appointment:book',
      cancel: 'manage_appointment:cancel',
      update: 'manage_appointment:update',
      amend: 'manage_appointment:amend',
      reschedule: 'manage_appointment:reschedule',
    },
    appointment_slot: {
      view: 'appointment_slot:view',
      update: 'appointment_slot:update',
      remove: 'appointment_slot:remove',
    },
  },

  notification: {
    email_and_inapp: {
      view: 'email_and_inapp:view',
      create: 'email_and_inapp:save',
      edit: 'email_and_inapp:update',
      circleMember: 'email_and_inapp:circle_member',
      emailAddress: 'email_and_inapp:email_address',
      customSelection: 'email_and_inapp:custom_selection',
      scheduleNotification: 'email_and_inapp:schedule_notification',
      recurrenceNotification: 'email_and_inapp:recurrence_notification',
      notificationType: 'email_and_inapp:notification_type',
    },
    sms: {
      view: 'sms:sms_view',
      create: 'sms:save',
      schedule: 'sms:sms_schedule',
      edit: 'sms:sms_edit',
    },
    sms_template: {
      view: 'sms_template:view',
      create: 'sms_template:save',
      update: 'sms_template:update',
      activeTemplate: 'sms_template:active_sms_template',
      inactiveTemplate: 'sms_template:inactive_sms_template',
      archivedTemplate: 'sms_template:archived_sms_template',
    },
  },
  circle: {
    circle_patient: {
      view: 'circle_patient:view',
      request: 'circle_patient:request',
      receive: 'circle_patient:receive',
    },
    circle_admin: {
      view: 'circle_admin:view',
      request: 'circle_admin:request',
      receive: 'circle_admin:receive',
    },
    circle_clinician: {
      view: 'circle_clinician:view',
      request: 'circle_clinician:request',
      receive: 'circle_clinician:receive',
    },
    circle_message_patient: {
      view: 'circle_message_patient:view',
      send: 'circle_message_patient:send',
      receive: 'circle_message_patient:receive',
    },
    circle_message_admin: {
      view: 'circle_message_admin:view',
      send: 'circle_message_admin:send',
      receive: 'circle_message_admin:receive',
    },
    circle_message_clinician: {
      view: 'circle_message_clinician:view',
      send: 'circle_message_clinician:send',
      receive: 'circle_message_clinician:receive',
    },
  },

  questionnaire: {
    questionnaire_form: {
      view: 'questionnaire_form:view',
      create: 'questionnaire_form:save',
      edit: 'questionnaire_form:update',
      delete: 'questionnaire_form:delete',
      share: 'questionnaire_form:share',
      approve: 'questionnaire_form:approve',
      answer: 'questionnaire_form:answer',
      publish: 'questionnaire_form:publish',
    },
  },

  resource_hub: {
    resource_management: {
      view: 'resource_management:view',
      create: 'resource_management:save',
      publishView: 'resource_management:publish_view',
      approve: 'resource_management:approve',
      publish: 'resource_management:publish',
    },
  },

  referral: {
    referral_management: {
      create: 'referral:save',
      view: 'referral:view',
      edit: 'referral:update',
      answer: 'referral:answer',
      triage: 'referral:triage',
      service: 'referral:services',
    },
    referral_access: {
      answer: 'referral_access:answer',
      triage: 'referral_access:triage',
      service: 'referral_access:services',
    },
  },

  role_management: {
    create: 'role:save',
    view: 'role:view',
    manage: 'role:update',
    deactivate: 'role:deactivate',
  },

  service_management: {
    create: 'service:save',
    view: 'service:view',
    manage: 'service:update',
    deactivate: 'service:deactivate',
  },

  user_management: {
    create: 'user:save',
    view: 'user:view',
    manage: 'user:update',
    deactivate: 'user:deactivate',
  },

  area_management: {
    view: 'area:view',
  },

  module_management: {
    create: 'module_management:save',
    view: 'module_management:view',
    manage: 'module_management:update',
    deactivate: 'module_management:deactivate',
  },
};
